import BaseApi from 'api/BaseApi';
import querparser from 'query-string';

export default class MerchantApi extends BaseApi {
  // static getOne = (key, id) => {
  //   return this.Axios.get(`hats/merchant/${id}`);
  // };

  static getAll = (key, query = { limit: 10, page: 1, sort: 'id', order: 'desc' }) => {
    return this.Axios.get(
      `hats/quiz?${querparser.stringify(query, {
        skipEmptyString: true,
        skipNull: true,
      })}`,
    );
  };

  // static create = (data) => {
  //   return this.Axios.post(`hats/merchant`, data);
  // };

  // static edit = ([id, data]) => {
  //   console.log(id, data);
  //   return this.Axios.post(`hats/merchant/${id}`, data);
  // };

  static toggleActive = (id, status) => {
    return this.Axios.post(`hats/quiz/${id}/status?status=${status}`);
  };
}
