import React from 'react';
import { Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';
import Loading from '../components/Loading';
import { useSelector } from 'react-redux';
import { selectors } from 'modules/auth';
import PermittedRoute from 'components/PermittedRouts';
import permissions from 'utils/adminPermission/permissions';

const Overview = loadable(() => import('./Overview'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const Merchants = loadable(() => import('./Merchants'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const MerchantDetails = loadable(() => import('./merchants/Details'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const Customers = loadable(() => import('./Customers'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const CustomerDetails = loadable(() => import('./customers/Details'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const TransactionsDetails = loadable(() => import('./customers/TransactionsDetails'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const Categories = loadable(() => import('./Categories'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const Tags = loadable(() => import('./Tags'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const Cashbacks = loadable(() => import('./Cashbacks'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const GiftReason = loadable(() => import('./GiftReason'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const SingleCashbacksDetails = loadable(() => import('./cashbacks/Details'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const Vouchers = loadable(() => import('./Vouchers'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const VoucherRequests = loadable(() => import('./VoucherRequests'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const VoucherCode = loadable(() => import('./VoucherCode'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const TopUp = loadable(() => import('./TopUp'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const TopUpFailed = loadable(() => import('./TopUpFailed'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const Points = loadable(() => import('./Wallet'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const MerchantPayments = loadable(() => import('./MerchantPayments'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const Payments = loadable(() => import('./Payments'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const Banners = loadable(() => import('./Banners'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const EditMerchant = loadable(() => import('./EditMerchant'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const Notice = loadable(() => import('./Notice'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const Settings = loadable(() => import('./Settings'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const Reviews = loadable(() => import('./Reviews'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const Games = loadable(() => import('./Games'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const QuizPage = loadable(() => import('./quiz/Quizzes'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const QuizDetailPage = loadable(() => import('./quiz/QuizDetailPage'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const CreateQuizPage = loadable(() => import('./quiz/CreateQuizPage'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const EditQuizPage = loadable(() => import('./quiz/EditQuizPage'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const WheelsPage = loadable(() => import('./luckywheel/WheelsPage'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const CreateLuckyWheelPage = loadable(() => import('./luckywheel/CreateLuckyWheelPage'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const UpdateLuckyWheelPage = loadable(() => import('./luckywheel/UpdateLuckyWheelPage'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const AddBannerPage = loadable(() => import('./adsBanner/AddBannerPage'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const AdsBannerListPage = loadable(() => import('./adsBanner/AdsBannerListPage'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const AllWheelReportPage = loadable(() => import('./allWheelReport/AllWheelReportPage'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const WheelMerchantPartnerReportPage = loadable(
  () => import('./wheelMerchant/ReportsWheelMerchantPartner'),
  {
    fallback: Loading({
      pastDelay: true,
      error: false,
      timedOut: false,
    }),
  },
);

const SingleWheelSummaryPage = loadable(
  () => import('./singleWheelSummary/SingleWheelSummaryPage'),
  {
    fallback: Loading({
      pastDelay: true,
      error: false,
      timedOut: false,
    }),
  },
);
const SurveyPage = loadable(() => import('./Survey'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const CreateSurveyPage = loadable(() => import('./SurveyForm/CreateSurvey'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const SurveyListPage = loadable(() => import('./SurveyListPage/SurveyListPage'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const Blogs = loadable(() => import('./Blog'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const MerchantJoinRequest = loadable(() => import('./MerchantJoinRequest'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const WheelMerchant = loadable(() => import('./WheelMerchant'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const WheelMerchantPartner = loadable(() => import('./wheelMerchant/WheelMerchantPartner'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const CustomersCommunication = loadable(() => import('./Communication'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const QuizReportsPage = loadable(() => import('./quiz/QuizReportsPage'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const PSReview = loadable(() => import('./PSReview'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const AdminManagement = loadable(() => import('./AdminManagement'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const AdminManagementDetails = loadable(() => import('./adminManagement/Details'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const HappicardWaitLists = loadable(() => import('./HappicardWaitList'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const CreditScoring = loadable(() => import('./creditScoring'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
const NoPermission = loadable(() => import('./../components/NoPermission'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

// const TestsDetails = loadable(() => import('./tests/Details'), {
//   fallback: Loading({
//     pastDelay: true,
//     error: false,
//     timedOut: false,
//   }),
// });

const Routes = (props) => {
  return (
    <Switch>
      <PermittedRoute
        exact
        path="/admin-management/:id"
        component={AdminManagementDetails}
        permissions={permissions.admin_management}
      />
      <PermittedRoute
        exact
        path="/admin-management"
        component={AdminManagement}
        permissions={permissions.admin_management}
      />
      <PermittedRoute
        path="/gift-reason"
        component={GiftReason}
        permissions={permissions.wallets}
      />
      <PermittedRoute
        path="/communication"
        component={CustomersCommunication}
        permissions={permissions.communications}
      />
      <PermittedRoute
        path="/survey/list"
        component={SurveyListPage}
        permissions={permissions.games}
      />
      <PermittedRoute
        path="/survey/create/:id"
        component={CreateSurveyPage}
        permissions={permissions.games}
      />
      <PermittedRoute
        path="/survey/create"
        component={CreateSurveyPage}
        permissions={permissions.games}
      />
      <PermittedRoute path="/survey" component={SurveyPage} permissions={permissions.games} />
      <PermittedRoute
        path="/games/luckywheel/report/:id"
        component={SingleWheelSummaryPage}
        permissions={permissions.games}
      />
      <PermittedRoute
        path="/games/luckywheel/edit/:id"
        component={UpdateLuckyWheelPage}
        permissions={permissions.games}
      />
      <PermittedRoute
        path="/games/luckywheel/create"
        component={CreateLuckyWheelPage}
        permissions={permissions.games}
      />
      <PermittedRoute
        path="/games/luckywheel/all-report"
        component={AllWheelReportPage}
        permissions={permissions.games}
      />
      <PermittedRoute
        path="/games/luckywheel/"
        component={WheelsPage}
        permissions={permissions.games}
      />
      <PermittedRoute
        path="/games/quiz/reports"
        component={QuizReportsPage}
        permissions={permissions.games}
      />
      <PermittedRoute
        path="/games/quiz/details/:id"
        component={QuizDetailPage}
        permissions={permissions.games}
      />
      <PermittedRoute
        path="/games/quiz/edit/:id"
        component={EditQuizPage}
        permissions={permissions.games}
      />
      <PermittedRoute
        path="/games/quiz/create"
        component={CreateQuizPage}
        permissions={permissions.games}
      />
      <PermittedRoute path="/games/quiz/" component={QuizPage} permissions={permissions.games} />
      <PermittedRoute
        path="/merchants/edit/:id"
        component={EditMerchant}
        permissions={permissions.merchants}
      />
      <PermittedRoute
        path="/credit_scoring"
        component={CreditScoring}
        permissions={permissions.merchants}
      />
      <PermittedRoute
        path="/merchants/:id"
        component={MerchantDetails}
        permissions={permissions.merchants}
      />
      <PermittedRoute path="/merchants" component={Merchants} permissions={permissions.merchants} />
      <PermittedRoute
        path="/cashbacks/:id"
        component={SingleCashbacksDetails}
        permissions={permissions.bill_claims}
      />
      <PermittedRoute
        path="/cashbacks"
        component={Cashbacks}
        permissions={permissions.bill_claims}
      />
      <PermittedRoute
        path="/customers/:id"
        component={CustomerDetails}
        permissions={permissions.customers}
      />
      <PermittedRoute
        path="/transactions/:id"
        component={TransactionsDetails}
        permissions={permissions.wallets}
      />
      <PermittedRoute path="/customers" component={Customers} permissions={permissions.customers} />
      <PermittedRoute
        path="/categories"
        component={Categories}
        permissions={permissions.categories}
      />
      {/* <Route path="/voucher-code" component={VoucherCode} /> */}
      <PermittedRoute path="/vouchers" component={Vouchers} permissions={permissions.vouchers} />
      <PermittedRoute path="/reviews" component={Reviews} permissions={permissions.reviews} />
      <PermittedRoute
        path="/voucher-requests"
        component={VoucherRequests}
        permissions={permissions.voucher_requests}
      />
      <Route path="/tags" component={Tags} />
      <PermittedRoute path="/mobile-recharge" component={TopUp} permissions={permissions.topups} />
      <PermittedRoute
        path="/topup-failed"
        component={TopUpFailed}
        permissions={permissions.topups.read}
      />

      <PermittedRoute path="/points" component={Points} permissions={permissions.wallets} />
      <PermittedRoute
        path="/metchat-payments"
        component={MerchantPayments}
        permissions={permissions.wallets}
      />
      <PermittedRoute path="/payments" component={Payments} permissions={permissions.wallets} />
      <Route path="/banners" component={Banners} />
      <PermittedRoute path="/games" component={Games} permissions={permissions.games} />
      <Route path="/settings" component={Settings} />
      <PermittedRoute path="/notice" component={Notice} permissions={permissions.notice} />
      <Route path="/ads-banner/create" component={AddBannerPage} />
      <Route path="/ads-banner" component={AdsBannerListPage} />
      <PermittedRoute path="/blogs" component={Blogs} permissions={permissions.blog} />

      <Route path="/merchant-join-request" component={MerchantJoinRequest} />
      <PermittedRoute
        path="/wheel-merchant"
        component={WheelMerchant}
        permissions={permissions.games}
      />
      <PermittedRoute
        path="/wheel-merchant-list"
        component={WheelMerchantPartner}
        permissions={permissions.games}
      />
      <PermittedRoute
        path="/wheel-merchant-report"
        component={WheelMerchantPartnerReportPage}
        permissions={permissions.games}
      />
      <Route path="/happicard_waitlists" component={HappicardWaitLists} />
      <PermittedRoute path="/psreview" component={PSReview} permissions={permissions.reviews} />
      <PermittedRoute path="/" component={Overview} permissions={permissions.dashboard} />
    </Switch>
  );
};

export default Routes;
