import { createSlice, createSelector } from '@reduxjs/toolkit';
import TopupApi from 'api/TopupApi';
import Topups from '../repositories/Topups';
import { enqueueSnackbar } from './notifications';
// import { enqueueSnackbar } from './notifications';

const topupSlice = createSlice({
  name: 'topup',
  initialState: {
    loading: false,
    responding: false,
    error: null,
    data: [],
    reTryTopupError: '',
    reTryTopupMsg: '',
    toggleLoading: false,
    topupResponseMessage: '',
  },
  reducers: {
    getAll: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    getAllSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
    },
    getAllFailed: (state, action) => {
      state.loading = false;
      state.error = null;
    },
    getAllOperator: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    getAllOperatorSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
    },
    getAllOperatorFailed: (state, action) => {
      state.loading = false;
      state.error = null;
    },
    toggleActive: (state, action) => {
      state.toggleLoading = true;
      state.error = null;
    },
    toggleActiveSuccess: (state, action) => {
      state.toggleLoading = false;
      state.error = null;
      // const index = state.data.findIndex((item) => item.id === action.payload.id);
      // console.log(state.data)
      // // state.data[index].status = action.payload.status;
    },
    toggleActiveFailed: (state, action) => {
      state.toggleLoading = false;
      state.error = null;
    },
    retryTopup: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    retryTopupSuccess: (state, action) => {
      state.reTryTopupMsg = action.payload;
      state.reTryTopupError = null;
    },
    retryTopupFailed: (state, action) => {
      state.reTryTopupError = action.payload;
      state.reTryTopupMsg = null;
    },
    edit: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    editSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = state.data.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload;
        }
        return item;
      });
      state.topupResponseMessage = action.payload.message;
    },
    editFailed: (state, action) => {
      state.loading = false;
      state.error = null;
      state.topupResponseMessage = action.payload.message;
    },
  },
});

const { actions, reducer } = topupSlice;

const getTopups = (state) => state.topup.data;
const getIsLoading = (state) => state.topup.loading;
const getToggleLoading = (state) => state.topup.toggleLoading;
const selectors = {
  data: createSelector(getTopups, (data) => data),
  isLoading: createSelector(getIsLoading, (data) => data),
  toggleLoading: createSelector(getToggleLoading, (data) => data),
};

const getAll = (query) => async (dispatch) => {
  dispatch(actions.getAll());
  try {
    const response = await Topups.getAll(query);
    if (response.success || response.status === 200) {
      dispatch(actions.getAllSuccess(response));
      return {
        data: response.data,
        page: response.current_page - 1,
        totalCount: response.total,
      };
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Get All Topup Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getAllFailed(error.message));
    return error;
  }
};

const getAllOperator = (query) => async (dispatch) => {
  dispatch(actions.getAllOperator());
  try {
    const response = await Topups.getAllOperator(query);
    if (response.success || response.status === 200) {
      dispatch(actions.getAllOperatorSuccess(response));
      return {
        data: response.data,
        page: response.current_page - 1,
        totalCount: response.total,
      };
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Get All Topup Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getAllOperatorFailed(error.message));
    return error;
  }
};

const toggleActive = (id, is_enable) => async (dispatch) => {
  console.log({ id, is_enable });
  dispatch(actions.toggleActive());
  try {
    const response = await TopupApi.toggleActive(id, is_enable);

    if (response.success || response.status === 200) {
      dispatch(actions.toggleActiveSuccess(response.data));
      dispatch(
        enqueueSnackbar({
          message: 'Topup Config Activated Successfully.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
      return {
        data: response.data,
        message: response.message,
        status: response.status,
        success: response.success,
      };
    } else {
      throw Error(response.errMgs ? response.errMgs : `Failed to activate Topup config ${id}`);
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.toggleActiveFailed(error.message));
    dispatch(
      enqueueSnackbar({
        message: 'Cannot Activate Topup Config.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
    return error;
  }
};

const makeRetryTopupRequest = (id) => async (dispatch) => {
  dispatch(actions.retryTopup());
  try {
    const response = await Topups.makeRetryTopup(id);
    if (response.success || response.status === 200) {
      dispatch(actions.retryTopupSuccess(response.message));
      return { ...response };
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Retry  Failed');
    }
  } catch (error) {
    dispatch(actions.retryTopupFailed(error.message));
    console.log(error.message);
    return error;
  }
};

const edit = (id, values, formData) => async (dispatch, getState) => {
  dispatch(actions.edit());
  try {
    const response = await Topups.edit(id, values, formData);
    if (response.success || response.status === 200) {
      dispatch(actions.editSuccess(response.data));
      dispatch(
        enqueueSnackbar({
          message: 'Topup Edited Succesfully.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
      return {
        data: response.data,
        message: response.message,
        status: response.status,
        success: response.success,
      };
    } else {
      let e = new Error(response.messsage ? response.message : 'Topup Edit Failed');
      e.response = response;
      return e;
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.editFailed(error.message));
    return error.response;
  }
};

export default {
  actions,
  reducer,
  selectors,
  getAll,
  getAllOperator,
  toggleActive,
  makeRetryTopupRequest,
  edit,
};
