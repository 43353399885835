import SuperFetch, { SuperUploader } from '../api/SuperFetch';
import querparser from 'query-string';

class HappicardWaitList {
  getAll(query = { limit: 10, page: 1 }) {
    return SuperFetch(`hats/happicard/subscribers?${querparser.stringify(query)}`);
  }
}

export default new HappicardWaitList();
