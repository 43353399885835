import { createSlice, createSelector } from '@reduxjs/toolkit';
import Cashbacks from '../repositories/Cashbacks';
import { enqueueSnackbar } from './notifications';

const cashbacksSlice = createSlice({
  name: 'cashbacks',
  initialState: {
    loading: false,
    productLoading: false,
    responding: false,
    error: null,
    data: [],
    single: {
      amount: 0,
    },
    products: [],
    singleLoading: false,
    metaKeys: [],
    metaKeysLoading: false,
    metaValues: [],
    metaValuesLoading: false,
  },
  reducers: {
    getAll: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    getAllSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
    },
    getAllFailed: (state, action) => {
      state.loading = true;
      state.error = null;
    },

    getAllProducts: (state, action) => {
      state.productLoading = true;
      state.error = null;
    },
    getAllProductsSuccess: (state, action) => {
      state.productLoading = false;
      state.error = null;
      state.products = action.payload.data;
    },
    getAllProductsFailed: (state, action) => {
      state.productLoading = true;
      state.error = null;
    },

    getOne: (state, action) => {
      state.singleLoading = true;
      state.error = null;
    },
    getOneSuccess: (state, action) => {
      const payload = action.payload.data;
      state.singleLoading = false;
      state.error = null;
      const cb = state.data.find((c) => c.id === payload.id);
      state.single = { ...cb, ...payload };
    },
    getOneFailed: (state, action) => {
      state.singleLoading = true;
      state.error = action.payload;
    },

    respond: (state, action) => {
      state.responding = true;
      state.error = null;
    },
    respondSuccess: (state, action) => {
      state.responding = false;
      state.error = null;
      state.data.forEach((element) => {
        if (element.id === action.payload.data.id) {
          element = action.payload.data;
        }
      });
    },
    respondFailed: (state, action) => {
      state.responding = false;
      state.error = true;
    },

    attachProducts: (state, action) => {
      state.responding = true;
      state.error = null;
    },
    attachProductsSuccess: (state, action) => {
      state.responding = false;
      state.error = null;
      // state.data.forEach(element => {
      //   if (element.id === action.payload.data.id) {
      //     element = action.payload.data;
      //   }
      // });
    },
    attachProductsFailed: (state, action) => {
      state.responding = false;
      state.error = true;
    },

    getMetaKeys: (state, action) => {
      state.metaKeysLoading = true;
      state.error = null;
    },
    getMetaKeysSuccess: (state, action) => {
      state.metaKeysLoading = true;
      state.error = null;
      state.metaKeys = action.payload.data;
    },
    getMetaKeysFailed: (state, action) => {
      state.metaKeysLoading = true;
      state.error = null;
    },

    getMetaValues: (state, action) => {
      state.metaValuesLoading = false;
      state.error = null;
    },
    getMetaValuesSuccess: (state, action) => {
      state.metaValuesLoading = false;
      state.error = null;
      state.metaValues = action.payload.data;
    },
    getMetaValuesFailed: (state, action) => {
      state.metaValuesLoading = false;
      state.error = null;
    },
  },
});

const { actions, reducer } = cashbacksSlice;

const getCashbacks = (state) => state.cashbacks.data;
const getSingleCashback = (state) => state.cashbacks.single;
const getCashbackAmount = (state) => state.cashbacks.single.amount;
const getIsLoading = (state) => state.cashbacks.loading;
const getSingleLoading = (state) => state.cashbacks.singleLoading;
const getAllMetaKeys = (state) => state.cashbacks.metaKeys;
const getMetaKeysLoading = (state) => state.cashbacks.metaKeysLoading;
const getAllMetaValues = (state) => state.cashbacks.metaValues;
const getMetaValuesLoading = (state) => state.cashbacks.metaValuesLoading;

const selectors = {
  data: createSelector(getCashbacks, (data) => data),
  single: createSelector(getSingleCashback, (data) => data),
  cashbackAmount: createSelector(getCashbackAmount, (data) => data),
  singleLoading: createSelector(getSingleLoading, (data) => data),
  isLoading: createSelector(getIsLoading, (data) => data),
  metaKeys: createSelector(getAllMetaKeys, (data) => data),
  metaKeysLoading: createSelector(getMetaKeysLoading, (data) => data),
  metaValues: createSelector(getAllMetaValues, (data) => data),
  metaValuesLoading: createSelector(getMetaValuesLoading, (data) => data),
  isResponding: createSelector(
    (state) => state.cashbacks.responding,
    (data) => data,
  ),
};

const getAll = (query) => async (dispatch) => {
  dispatch(actions.getAll());
  try {
    const response = await Cashbacks.getAll(query);
    if (response.success || response.status === 200) {
      dispatch(actions.getAllSuccess(response));
      return {
        data: response.data,
        page: response.current_page - 1,
        totalCount: response.total,
      };
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Get Cashback Claim Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getAllFailed(error.message));
    return error;
  }
};

const getAllProducts = (query) => async (dispatch) => {
  dispatch(actions.getAllProducts());
  try {
    const response = await Cashbacks.getAllProducts(query);
    if (response.success || response.status === 200) {
      dispatch(actions.getAllProductsSuccess(response));
      return {
        data: response.data,
        page: response.current_page - 1,
        totalCount: response.total,
      };
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Get Bill Products Failed');
    }
  } catch (error) {
    // console.log(error);
    dispatch(actions.getAllProductsFailed(error.message));
    return error;
  }
};
// TODO - finish api integration
const attachProducts = (query) => async (dispatch) => {
  dispatch(actions.attachProducts());
  try {
    const response = await Cashbacks.attachProducts(query);
    if (response.success || response.status === 200) {
      dispatch(actions.attachProductsSuccess(response));
      dispatch(
        enqueueSnackbar({
          message: 'Bill products attached successfully',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
      return response.data;
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Get Bill Products Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.attachProductsFailed(error.message));
    dispatch(
      enqueueSnackbar({
        message: 'Bill products attached failed',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
    return error;
  }
};

const getOne = (id) => async (dispatch) => {
  dispatch(actions.getOne());
  try {
    const response = await Cashbacks.getOne(id);
    if (response.success || response.status === 200) {
      dispatch(actions.getOneSuccess(response));
      return response.data;
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Get Cashback Claim Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getOneFailed(error.message));
    return error;
  }
};

const respond = (id, data) => async (dispatch) => {
  dispatch(actions.respond());
  try {
    const response = await Cashbacks.respond(id, data);
    if (response.success || response.status === 200) {
      dispatch(actions.respondSuccess(response));
      dispatch(
        enqueueSnackbar({
          message: 'Successful',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Responding to cashback claim failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.respondFailed(error.message));
    dispatch(
      enqueueSnackbar({
        message: 'ERROR - Duplicate bill / Invalid input!',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
    return error;
  }
};

const getMetaKeys = (query) => async (dispatch) => {
  dispatch(actions.getMetaKeys());
  try {
    const response = await Cashbacks.getMetaKeys(query);
    if (response.success || response.status === 200) {
      dispatch(actions.getMetaKeysSuccess(response));
      return {
        data: response.data,
        page: response.current_page - 1,
        totalCount: response.total,
      };
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Get Meta-keys failed!');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getMetaKeysFailed(error.message));
    return error;
  }
};

const getMetaValues = (query) => async (dispatch) => {
  dispatch(actions.getMetaValues());
  try {
    const response = await Cashbacks.getMetaValues(query);
    if (response.success || response.status === 200) {
      dispatch(actions.getMetaValuesSuccess(response));
      return {
        data: response.data,
        page: response.current_page - 1,
        totalCount: response.total,
      };
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Get Meta-values failed!');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getMetaValuesFailed(error.message));
    return error;
  }
};

export default {
  actions,
  reducer,
  selectors,
  getAll,
  getOne,
  getAllProducts,
  attachProducts,
  getMetaKeys,
  getMetaValues,
  respond,
};
