import { createSlice, createSelector } from '@reduxjs/toolkit';
import Blog from 'repositories/Blog';
import HappicardWaitList from 'repositories/HappicardWaitList';

const happicardWaitListSlice = createSlice({
  name: 'happicardWaitList',
  initialState: {
    loading: false,
    responding: false,
    error: null,
    data: [],
    blogImage: null,
  },
  reducers: {
    getAll: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    getAllSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
    },
    getAllFailed: (state, action) => {
      state.loading = false;
      state.error = null;
    },
  },
});

const { actions, reducer } = happicardWaitListSlice;

const getHappicardWaitLists = (state) => state.happicardWaitList.data;
const getIsLoading = (state) => state.happicardWaitList.loading;

const selectors = {
  data: createSelector(getHappicardWaitLists, (data) => data),
  isLoading: createSelector(getIsLoading, (data) => data),
};

const getAll = (query) => async (dispatch) => {
  dispatch(actions.getAll());
  try {
    const response = await HappicardWaitList.getAll(query);
    if (response.success || response.status === 200) {
      dispatch(actions.getAllSuccess(response));
      console.log(response.data);
      return {
        data: response.data,
        page: response.current_page - 1,
        totalCount: response.total,
      };
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Get All Happicard WaitLists Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getAllFailed(error.message));
    return error;
  }
};

export default {
  actions,
  reducer,
  selectors,
  getAll,
};
