import SuperFetch from '../api/SuperFetch';
import querparser from 'query-string';
import { API_ENDPOINTS } from 'utils/api/endpoints';

class VoucherRequestsRepo {
  getAll(query = { limit: 10, page: 1 }) {
    return SuperFetch(`voucher/orders?${querparser.stringify(query)}`);
  }

  accept(id) {
    return SuperFetch(`voucher/accept?order_id=${id}`, {
      method: 'POST',
    });
  }

  getCSV(query = { startDate: '', endDate: '' }) {
    const { startDate, endDate } = query;
    return SuperFetch(`hats/voucher-reports?start_date=${startDate}&end_date=${endDate}`);
  }

  downloadCSV(fileName) {
    return SuperFetch(`/reports/download?filename=${fileName}`);
  }
}

export default new VoucherRequestsRepo();
