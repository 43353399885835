import { createSlice, createSelector } from '@reduxjs/toolkit';
import QuizApi from 'api/QuizApi';

const quizzesSlice = createSlice({
  name: 'quizzes',
  initialState: {
    isLoading: false,
    creating: false,
    data: [],
    single: {},
    toggleLoading: false,
  },
  reducers: {
    getAll: (state, action) => {
      state.isLoading = true;
    },
    getAllSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
    },
    getAllFailed: (state, action) => {
      state.loading = false;
      state.error = null;
    },

    toggleActive: (state, action) => {
      state.toggleLoading = true;
      state.error = null;
    },
    toggleActiveSuccess: (state, action) => {
      state.toggleLoading = false;
      state.error = null;
      const index = state.data.findIndex((item) => item.id === action.payload.id);
      state.data[index].status = action.payload.status;
    },
    toggleActiveFailed: (state, action) => {
      state.toggleLoading = false;
      state.error = null;
    },
  },
});

const { actions, reducer } = quizzesSlice;

const getQuizzes = (state) => state.quizzes.data;
const getIsLoading = (state) => state.quizzes.loading;
const getIsCreating = (state) => state.quizzes.creating;
const getToggleLoading = (state) => state.quizzes.toggleLoading;

const selectors = {
  data: createSelector(getQuizzes, (data) => data),
  isLoading: createSelector(getIsLoading, (data) => data),
  isCreating: createSelector(getIsCreating, (data) => data),
  toggleLoading: createSelector(getToggleLoading, (data) => data),
};

const getAll = (query) => async (dispatch) => {
  dispatch(actions.getAll());
  try {
    const response = await QuizApi.getAll('quizzes', query);
    if (response.success || response.status === 200) {
      dispatch(actions.getAllSuccess(response.data));
      return {
        data: response.data,
        page: response.current_page - 1,
        totalCount: response.total,
      };
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Get Quizzes Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getAllFailed(error.message));
    return error;
  }
};

const toggleActive = (id, status) => async (dispatch) => {
  // dispatch(actions.toggleActive());
  try {
    const response = await QuizApi.toggleActive(id, status);
    if (response.status === 200) {
      // dispatch(actions.toggleActiveSuccess({ id, status }));
      return response;
    } else {
      throw Error(response.errMgs ? response.errMgs : `Failed to activate quiz ${id}`);
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.toggleActiveFailed());
    return error;
  }
};

export default {
  actions,
  reducer,
  selectors,
  getAll,
  toggleActive,
};
