import { createSlice, createSelector } from '@reduxjs/toolkit';
import Payments from '../repositories/Payments';
import { enqueueSnackbar } from './notifications';

const paymentsSlice = createSlice({
  name: 'payments',
  initialState: {
    loading: false,
    responding: false,
    error: null,
    data: [],
  },
  reducers: {
    getAll: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    getAllSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
    },
    getAllFailed: (state, action) => {
      state.loading = false;
      state.error = null;
    },

    makePayment: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    makePaymentSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
    },
    makePaymentFailed: (state, action) => {
      state.loading = false;
      state.error = null;
    },
  },
});

const { actions, reducer } = paymentsSlice;

const getPayments = state => state.payments.data;
const getIsLoading = state => state.payments.loading;

const selectors = {
  data: createSelector(
    getPayments,
    data => data,
  ),
  isLoading: createSelector(
    getIsLoading,
    data => data,
  ),
};

const getAll = query => async dispatch => {
  dispatch(actions.getAll());
  try {
    const response = await Payments.getAll(query);
    if (response.success || response.status === 200) {
      dispatch(actions.getAllSuccess(response));
      return {
        data: response.data,
        page: response.current_page - 1,
        totalCount: response.total,
      };
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Get All Payments Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getAllFailed(error.message));
    return error;
  }
};

const makePayment = input => async dispatch => {
  dispatch(actions.makePayment());
  try {
    const response = await Payments.makePayment(input);
    if (response.success || response.status === 200) {
      dispatch(actions.makePaymentSuccess(response));
      dispatch(
        enqueueSnackbar({
          message: 'Payment made succesfully.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
      return null;
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Get All Payments Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.makePaymentFailed(error.message));
    dispatch(
      enqueueSnackbar({
        message: 'Failed to make payment.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
    return error;
  }
};

export default {
  actions,
  reducer,
  selectors,
  getAll,
  makePayment,
};
