import { createSlice, createSelector } from '@reduxjs/toolkit';
import AdminManagement from 'repositories/AdminManagement';
import { enqueueSnackbar } from './notifications';

const adminManagementSlice = createSlice({
  name: 'adminManagement',
  initialState: {
    loading: false,
    locationLoading: false,
    error: null,
    data: [],
    adminManagementResponseMessage: '',
  },
  reducers: {
    getAllRole: (state, action) => {
      state.locationLoading = true;
      state.error = null;
    },
    getAllRoleSuccess: (state, action) => {
      state.locationLoading = false;
      state.error = null;
      state.role_name = action.payload.data;
    },
    getAllRoleFailed: (state, action) => {
      state.locationLoading = true;
      state.error = null;
    },

    create: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    createSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data.push(action.payload.data);
      state.adminManagementResponseMessage = action.payload.message;
    },
    createFailed: (state, action) => {
      state.loading = false;
      state.error = null;
      state.adminManagementResponseMessage = action.payload.message;
    },
  },
});

const { actions, reducer } = adminManagementSlice;

const getAdminManagement = (state) => state.adminManagement.data;
const getIsLoading = (state) => state.adminManagement.loading;

const selectors = {
  data: createSelector(getAdminManagement, (data) => data),
  isLoading: createSelector(getIsLoading, (data) => data),
  selectOptions: createSelector(getAdminManagement, (data) =>
    data.map(({ name }) => ({ value: name, label: name })),
  ),
};
const getAllRole = (query) => async (dispatch) => {
  dispatch(actions.getAllRole());
  try {
    const response = await AdminManagement.getAllRole(query);
    if (response.status === 200) {
      dispatch(actions.getAllRoleSuccess(response));
      return {
        data: response.data,
        // page: response.current_page - 1,
        // totalCount: response.total,
      };
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Get All Role Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getAllRoleFailed(error.message));
    return error;
  }
};

const create = (values) => async (dispatch, getState) => {
  dispatch(actions.create());
  try {
    const response = await AdminManagement.create(values);
    if (response.success || response.status === 201) {
      dispatch(actions.createSuccess(response));
      dispatch(
        enqueueSnackbar({
          message: 'Admin Created Succesfully.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
      return {
        data: response.data,
        message: response.message,
        status: response.status,
        success: response.success,
      };
    } else {
      let e = new Error(response.message ? response.message : 'Create Admin Failed');
      e.response = response;
      return e;
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.createFailed(error.message));
    return error.response;
  }
};

export default {
  actions,
  reducer,
  selectors,
  getAllRole,
  create,
};
