import { createSlice, createSelector } from '@reduxjs/toolkit';
import Vouchers from 'repositories/Vouchers';
import Wheel from 'repositories/Wheel';
import { enqueueSnackbar } from './notifications';

const wheelerMerchantsSlice = createSlice({
  name: 'wheelerMerchants',
  initialState: {
    loading: false,
    locationLoading: false,
    error: null,
    data: [],
    voucherResponseMessage: '',
  },
  reducers: {
    getAll: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    getAllSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
    },
    getAllFailed: (state, action) => {
      state.loading = true;
      state.error = null;
    },

    getAllLocations: (state, action) => {
      state.locationLoading = true;
      state.error = null;
    },
    getAllLocationsSuccess: (state, action) => {
      state.locationLoading = false;
      state.error = null;
      state.locations = action.payload.data;
    },
    getAllLocationsFailed: (state, action) => {
      state.locationLoading = true;
      state.error = null;
    },

    create: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    createSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data.push(action.payload.data);
      state.voucherResponseMessage = action.payload.message;
    },
    createFailed: (state, action) => {
      state.loading = false;
      state.error = null;
      state.voucherResponseMessage = action.payload.message;
    },
    edit: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    editSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = state.data.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload;
        }
        return item;
      });
      state.voucherResponseMessage = action.payload.message;
    },
    editFailed: (state, action) => {
      state.loading = false;
      state.error = null;
      state.voucherResponseMessage = action.payload.message;
    },
  },
});

const { actions, reducer } = wheelerMerchantsSlice;
const getWheelerMerchants = (state) => state.wheelerMerchants.data;
const getIsLoading = (state) => state.wheelerMerchants.loading;

const selectors = {
  data: createSelector(getWheelerMerchants, (data) => data),
  isLoading: createSelector(getIsLoading, (data) => data),
  selectOptions: createSelector(getWheelerMerchants, (data) =>
    data.map(({ name }) => ({ value: name, label: name })),
  ),
};

const create = (formData) => async (dispatch) => {
  dispatch(actions.create());
  try {
    const response = await Wheel.createWheel(formData);
    if (response.success || response.status === 200) {
      dispatch(actions.createSuccess(response));
      dispatch(
        enqueueSnackbar({
          message: 'Wheel Merchant Created succesfully.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
      return {
        ...response,
      };
    } else {
      throw Error(response.errMgs ? response.errMgs : ' Wheel Merchant Create Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.createBlogFailed(error.message));
    dispatch(
      enqueueSnackbar({
        message: 'Failed to create Wheel Merchant.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
    return error;
  }
};

const edit = (id, data) => async (dispatch, getState) => {
  dispatch(actions.edit());
  try {
    const response = await Wheel.editWheelMerchant(id, data);
    if (response.success || response.status === 200) {
      dispatch(actions.editSuccess(response.data));
      dispatch(
        enqueueSnackbar({
          message: 'Wheel Partner List Edited Succesfully.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
      return {
        data: response.data,
        message: response.message,
        status: response.status,
        success: response.success,
      };
    } else {
      let e = new Error(response.messsage ? response.message : 'Wheel Partner List Edit Failed');
      e.response = response;
      return e;
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.editFailed(error.message));
    return error.response;
  }
};

export default {
  actions,
  reducer,
  selectors,
  edit,
  create,
};
