import SuperFetch from '../api/SuperFetch';
import querparser from 'query-string';

class Reviews {
  getAll(query = { limit: 10, page: 1 }) {
    return SuperFetch(`hats/review?${querparser.stringify(query)}`);
  }

  remove(id) {
    return SuperFetch(`hats/review/${id}`, {
      method: 'DELETE',
    });
  }
}

export default new Reviews();
