import SuperFetch from '../api/SuperFetch';
import querparser from 'query-string';

class Wallet {
  getAll(query = { limit: 10, page: 1 }) {
    return SuperFetch(`hats/wallet/system/transactions?${querparser.stringify(query)}`);
  }

  getSystemBalance() {
    return SuperFetch(`hats/wallet/system/balance`);
  }

  getAdminBalance() {
    return SuperFetch(`hats/wallet/balance`);
  }

  sendGift(query) {
    return SuperFetch(`hats/wallet/gift/customers`, {
      method: 'POST',
      data: query,
    });
  }
  getAllReaon() {
    return SuperFetch(`hats/wallet/transactions/payment-reasons`);
  }

  getSingleTransactions(id, query = { limit: 30, page: 1 }) {
    return SuperFetch(
      `hats/wallet/system/transactions?customer_id=${id}&${querparser.stringify(query)}`,
    );
  }

  getAllPaymentReason() {
    return SuperFetch(`hats/wallet/transactions/payment-reasons`);
  }

  getAllSendGiftReason(query) {
    return SuperFetch(`hats/wallet/gift/gift-reasons?${querparser.stringify(query)}`);
  }
  getAllGiftReason(query = { limit: 20, page: 1 }) {
    return SuperFetch(`hats/wallet/gift/transactions?${querparser.stringify(query)}`);
  }
}

export default new Wallet();
