import SuperFetch, { SuperMallFetch } from '../api/SuperFetch';
import querparser from 'query-string';

class Payments {
  getAll(query = { limit: 10, page: 1 }) {
    return SuperFetch(`hats/payments?${querparser.stringify(query)}`);
  }

  makePayment(input) {
    return SuperFetch(`hats/payments?${querparser.stringify(input)}`, {
      method: 'POST',
    });
  }

  getAllPaymentList(query = { limit: 10, page: 1 }) {
    return SuperMallFetch(`admin/payments?${querparser.stringify(query)}`);
  }
}

export default new Payments();
