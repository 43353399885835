import SuperFetch from '../api/SuperFetch';
import querparser from 'query-string';

class Categories {
  getAll(query = { limit: 20, page: 1 }) {
    return SuperFetch(`hats/category?${querparser.stringify(query)}`);
  }
  create(values) {
    return SuperFetch('hats/category', {
      method: 'POST',
      data: values,
    });
  }
  edit(id, values) {
    return SuperFetch(`hats/category/${id}`, {
      method: 'PUT',
      data: values,
    });
  }
  // delete(values) {
  //   return SuperFetch('hats/category', {
  //     method: 'DELETE',
  //     body: JSON.stringify(values),
  //   });
  // }
}

export default new Categories();
