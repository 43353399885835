import { createSlice, createSelector } from '@reduxjs/toolkit';
import LuckyWheel from 'repositories/LuckyWheel';

const wheelsSlice = createSlice({
  name: 'wheels',
  initialState: {
    loading: false,
    error: null,
    data: [],
  },
  reducers: {
    getAll: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    getAllSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
    },
    getAllFailed: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    getAllReport: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    getAllReportSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
    },
    getAllReportFailed: (state, action) => {
      state.loading = true;
      state.error = null;
    },
  },
});

const { actions, reducer } = wheelsSlice;

const getWheels = (state) => state.wheels.data;
const getWheelReport = (state) => state.wheels.data;
const getIsLoading = (state) => state.wheels.loading;

const selectors = {
  data: createSelector(getWheels, (data) => data),
  getWheelReport: createSelector(getWheelReport, (data) => data),
  isLoading: createSelector(getIsLoading, (data) => data),
};
const getAll = (query) => async (dispatch) => {
  dispatch(actions.getAll());
  try {
    const response = await LuckyWheel.getAll(query);
    if (response.success || response.status === 200) {
      dispatch(actions.getAllSuccess(response));
      return {
        data: response.data,
        page: response.current_page - 1,
        totalCount: response.total,
      };
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Get All Review Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getAllFailed(error.message));
    return error;
  }
};
const getAllReport = (query) => async (dispatch) => {
  dispatch(actions.getAllReport());
  try {
    const response = await LuckyWheel.allReport(query);
    if (response.success || response.status === 200) {
      dispatch(actions.getAllReportSuccess(response));
      return {
        data: response.data,
        page: response.current_page - 1,
        totalCount: response.total,
      };
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Get All Wheel Report Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getAllFailed(error.message));
    return error;
  }
};

export default {
  actions,
  reducer,
  selectors,
  getAll,
  getAllReport,
};
