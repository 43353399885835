import SuperFetch, { SuperUploader } from '../api/SuperFetch';
import querparser from 'query-string';

class Wheel {
  getAllWheel(query = { limit: 10, page: 1 }) {
    return SuperFetch(`hats/wheeler-merchant?${querparser.stringify(query)}`);
  }
  createWheel(data) {
    console.log('data', data);
    return SuperFetch(`hats/wheeler-merchant/store`, {
      method: 'POST',
      data: data,
    });
  }
  editWheelMerchant(id, query) {
    return SuperUploader(`hats/wheeler-merchant/${id}?${querparser.stringify(query)}`);
  }

  wheelMerchantStatus(id, status) {
    return SuperFetch(`hats/wheeler-merchant/${id}?is_active=${status}`, {
      method: 'POST',
    });
  }
}

export default new Wheel();
