//all permission name
const permissions = {
  dashboard: {
    read: 'dashboard.read',
  },
  merchants: {
    create: 'merchants.create',
    read: 'merchants.read',
    update: 'merchants.update',
    delete: 'merchants.delete',
  },
  customers: {
    create: 'customers.create',
    read: 'customers.read',
    update: 'customers.update',
    delete: 'customers.delete',
  },

  topups: {
    create: 'topups.create',
    read: 'topups.read',
    update: 'topups.update',
    delete: 'topups.delete',
  },
  wallets: {
    create: 'wallets.create',
    read: 'wallets.read',
    update: 'wallets.update',
    delete: 'wallets.delete',
  },
  bill_claims: {
    create: 'bill_claims.create',
    read: 'bill_claims.read',
    update: 'bill_claims.update',
    delete: 'bill_claims.delete',
  },
  reviews: {
    create: 'reviews.create',
    read: 'reviews.read',
    update: 'reviews.update',
    delete: 'reviews.delete',
  },
  vouchers: {
    create: 'vouchers.create',
    read: 'vouchers.read',
    update: 'vouchers.update',
    delete: 'vouchers.delete',
  },
  voucher_requests: {
    create: 'voucher_requests.create',
    read: 'voucher_requests.read',
    update: 'voucher_requests.update',
    delete: 'voucher_requests.delete',
  },
  notice: {
    create: 'notice.create',
    read: 'notice.read',
    update: 'notice.update',
    delete: 'notice.delete',
  },
  games: {
    create: 'games.create',
    read: 'games.read',
    update: 'games.update',
    delete: 'games.delete',
  },
  communications: {
    create: 'communications.create',
    read: 'communications.read',
    update: 'communications.update',
    delete: 'communications.delete',
  },
  categories: {
    create: 'categories.create',
    read: 'categories.read',
    update: 'categories.update',
    delete: 'categories.delete',
  },
  blog: {
    create: 'blog.create',
    read: 'blog.read',
    update: 'blog.update',
    delete: 'blog.delete',
  },
  admin_management: {
    create: 'admin_management.create',
    read: 'admin_management.read',
    update: 'admin_management.update',
    delete: 'admin_management.delete',
  },
};

export default permissions;
