import { createSlice, createSelector } from '@reduxjs/toolkit';
import Reviews from 'repositories/Reviews';
import { enqueueSnackbar } from './notifications';

const reviewsSlice = createSlice({
  name: 'reviews',
  initialState: {
    loading: false,
    error: null,
    data: [],
  },
  reducers: {
    getAll: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    getAllSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
    },
    getAllFailed: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    remove: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    removeSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = state.data.filter(item => {
        return item.id !== action.payload;
      });
    },
    removeFailed: (state, action) => {
      state.loading = false;
      state.error = null;
    },
  },
});

const { actions, reducer } = reviewsSlice;

const getReviews = state => state.reviews.data;
const getIsLoading = state => state.reviews.loading;

const selectors = {
  data: createSelector(
    getReviews,
    data => data,
  ),
  isLoading: createSelector(
    getIsLoading,
    data => data,
  ),
};

const getAll = query => async dispatch => {
  dispatch(actions.getAll());
  try {
    const response = await Reviews.getAll(query);
    if (response.success || response.status === 200) {
      dispatch(actions.getAllSuccess(response));
      return {
        data: response.data,
        page: response.current_page - 1,
        totalCount: response.total,
      };
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Get All Review Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getAllFailed(error.message));
    return error;
  }
};

const remove = (id, values) => async (dispatch, getState) => {
  dispatch(actions.remove());
  try {
    const response = await Reviews.remove(id, values);
    if (response.success || response.status === 200) {
      dispatch(actions.removeSuccess(id));
      dispatch(
        enqueueSnackbar({
          message: 'Review Deleted Succesfully.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Review Edit Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.removeFailed(error.message));
  }
};

export default {
  actions,
  reducer,
  selectors,
  getAll,
  remove,
};
