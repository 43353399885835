import SuperFetch, { SuperUploader } from '../api/SuperFetch';
import querparser from 'query-string';
class AdminManage {
  getAllAdmin() {
    return SuperFetch(`hats/admin-managements/admins`);
  }
  getAllRole() {
    return SuperFetch(`hats/admin-managements/roles`);
  }
  getPermissions() {
    return SuperFetch(`hats/admin-managements/permissions?limit=50`);
  }
  updatePermissions(id, query) {
    return SuperFetch(`hats/admin-managements/permissions/assign/${id}`, {
      method: 'POST',
      data: query,
    });
  }
  create(data) {
    console.log(data);
    return SuperFetch(`hats/create-admin`, {
      method: 'POST',
      data: data,
    });
  }
}

export default new AdminManage();
