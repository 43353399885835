import { createSlice, createSelector } from '@reduxjs/toolkit';
import Vouchers from 'repositories/Vouchers';
import { enqueueSnackbar } from './notifications';

const vouchersSlice = createSlice({
  name: 'vouchers',
  initialState: {
    loading: false,
    locationLoading: false,
    error: null,
    data: [],
    voucherResponseMessage: '',
  },
  reducers: {
    getAll: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    getAllSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
    },
    getAllFailed: (state, action) => {
      state.loading = true;
      state.error = null;
    },

    getAllLocations: (state, action) => {
      state.locationLoading = true;
      state.error = null;
    },
    getAllLocationsSuccess: (state, action) => {
      state.locationLoading = false;
      state.error = null;
      state.locations = action.payload.data;
    },
    getAllLocationsFailed: (state, action) => {
      state.locationLoading = true;
      state.error = null;
    },

    create: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    createSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data.push(action.payload.data);
      state.voucherResponseMessage = action.payload.message;
    },
    createFailed: (state, action) => {
      state.loading = false;
      state.error = null;
      state.voucherResponseMessage = action.payload.message;
    },
    edit: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    editSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = state.data.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload;
        }
        return item;
      });
      state.voucherResponseMessage = action.payload.message;
    },
    editFailed: (state, action) => {
      state.loading = false;
      state.error = null;
      state.voucherResponseMessage = action.payload.message;
    },
    remove: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    removeSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = state.data.filter((item) => {
        return item.id !== action.payload;
      });
      state.voucherResponseMessage = action.payload.message;
    },
    removeFailed: (state, action) => {
      state.loading = false;
      state.error = null;
      state.voucherResponseMessage = action.payload.message;
    },
    makeTrending: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    makeTrendingSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      // state.data = state.data.filter(item => {
      //   return item.id !== action.payload;
      // });
      state.voucherResponseMessage = action.payload.message;
    },
    makeTrendingFailed: (state, action) => {
      state.loading = false;
      state.error = true;
      state.voucherResponseMessage = action.payload.message;
    },
    removeTrending: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    removeTrendingSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      // state.data = state.data.filter(item => {
      //   return item.id !== action.payload;
      // });
      state.voucherResponseMessage = action.payload.message;
    },
    removeTrendingFailed: (state, action) => {
      state.loading = false;
      state.error = true;
      state.voucherResponseMessage = action.payload.message;
    },
  },
});

const { actions, reducer } = vouchersSlice;

const getVouchers = (state) => state.vouchers.data;
const getIsLoading = (state) => state.vouchers.loading;

const selectors = {
  data: createSelector(getVouchers, (data) => data),
  isLoading: createSelector(getIsLoading, (data) => data),
  selectOptions: createSelector(getVouchers, (data) =>
    data.map(({ name }) => ({ value: name, label: name })),
  ),
};

const getAll = (query) => async (dispatch) => {
  dispatch(actions.getAll());
  try {
    const response = await Vouchers.getAll(query);
    if (response.success || response.status === 200) {
      dispatch(actions.getAllSuccess(response));
      return {
        data: response.data,
        page: response.current_page - 1,
        totalCount: response.total,
      };
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Get All Voucher Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getAllFailed(error.message));
    return error;
  }
};

const getAllLocations = (query) => async (dispatch) => {
  dispatch(actions.getAllLocations());
  try {
    const response = await Vouchers.getAllLocations(query);
    if (response.success || response.status === 200) {
      dispatch(actions.getAllLocationsSuccess(response));
      return {
        data: response.data,
        page: response.current_page - 1,
        totalCount: response.total,
      };
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Get Locations Failed');
    }
  } catch (error) {
    // console.log(error);
    dispatch(actions.getAllLocationsFailed(error.message));
    return error;
  }
};

const create = (values, formData) => async (dispatch, getState) => {
  dispatch(actions.create());
  try {
    const response = await Vouchers.create(values, formData);
    if (response.success || response.status === 201) {
      dispatch(actions.createSuccess(response));
      dispatch(
        enqueueSnackbar({
          message: 'Voucher Created Succesfully.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
      return {
        data: response.data,
        message: response.message,
        status: response.status,
        success: response.success,
      };
    } else {
      let e = new Error(response.message ? response.message : 'Create Voucher Failed');
      e.response = response;
      return e;
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.createFailed(error.message));
    return error.response;
  }
};

const edit = (id, values, formData) => async (dispatch, getState) => {
  dispatch(actions.edit());
  try {
    const response = await Vouchers.edit(id, values, formData);
    if (response.success || response.status === 200) {
      dispatch(actions.editSuccess(response.data));
      dispatch(
        enqueueSnackbar({
          message: 'Voucher Edited Succesfully.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
      return {
        data: response.data,
        message: response.message,
        status: response.status,
        success: response.success,
      };
    } else {
      let e = new Error(response.messsage ? response.message : 'Voucher Edit Failed');
      e.response = response;
      return e;
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.editFailed(error.message));
    return error.response;
  }
};

const remove = (id, values) => async (dispatch, getState) => {
  dispatch(actions.remove());
  try {
    const response = await Vouchers.remove(id, values);
    if (response.success || response.status === 200) {
      dispatch(actions.removeSuccess(id));
      dispatch(
        enqueueSnackbar({
          message: 'Voucher Deleted Succesfully.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
      return {
        ...response,
      };
    } else {
      throw Error(response.message ? response.message : 'Voucher Delete Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.removeFailed(error.message));
  }
};

const makeTrending = (id, values) => async (dispatch, getState) => {
  dispatch(actions.makeTrending());
  try {
    const response = await Vouchers.makeTrending(id, values);

    if (response.success || response.status === 200) {
      dispatch(actions.makeTrendingSuccess(id));
      dispatch(
        enqueueSnackbar({
          message: 'Voucher Trending Succesfully.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Voucher Edit Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.makeTrendingFailed(error.message));
  }
};

const removeTrending = (id, values) => async (dispatch, getState) => {
  dispatch(actions.removeTrending());
  try {
    const response = await Vouchers.removeTrending(id, values);
    if (response.success || response.status === 200) {
      dispatch(actions.removeTrendingSuccess(id));
      dispatch(
        enqueueSnackbar({
          message: 'Voucher Trending Removed Succesfully.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Failed toVoucher Removed from Trending');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.removeTrendingFailed(error.message));
  }
};

export default {
  actions,
  reducer,
  selectors,
  getAll,
  getAllLocations,
  create,
  edit,
  remove,
  makeTrending,
  removeTrending,
};
