import { Dashboard } from '@material-ui/icons';

export const setPermission = (permissions) => {
  localStorage.setItem('permissions', permissions);
};

export function getPermission() {
  let permissionList = localStorage.getItem('permissions');
  return JSON.parse(permissionList);
}
let permissionList = getPermission();

function hasPermission(values, requiredAll) {
  if (typeof values === 'string') {
    values = [values];
  } else {
    values = Object.values(values);
  }

  if (requiredAll) {
    return values.every((el) => permissionList.includes(el));
  } else {
    return values.some((el) => permissionList?.includes(el));
  }
}

export default hasPermission;
