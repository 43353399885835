import { createSlice, createSelector } from '@reduxjs/toolkit';
import MerchantApi from '../repositories/Merchants';
import { enqueueSnackbar } from './notifications';
import download from 'utils/downloadFile';

const merchantsSlice = createSlice({
  name: 'merchants',
  initialState: {
    loading: false,
    creating: false,
    error: null,
    data: [],
    single: {},
    toggleLoading: false,
  },
  reducers: {
    getAll: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    getAllSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
    },
    getAllFailed: (state, action) => {
      state.loading = false;
      state.error = null;
    },

    getOne: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    getOneSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.single = action.payload.data;
    },
    getOneFailed: (state, action) => {
      state.loading = false;
      state.error = null;
    },

    create: (state, action) => {
      state.creating = true;
      state.error = null;
    },
    createSuccess: (state, action) => {
      state.creating = false;
      state.error = null;
      state.data.unshift(action.payload.data);
    },
    createFailed: (state, action) => {
      state.creating = false;
      state.error = null;
    },

    edit: (state, action) => {
      state.creating = true;
      state.error = null;
    },
    editSuccess: (state, action) => {
      state.creating = false;
      state.error = null;
      // state.single = action.payload.data;
      // state.data.unshift(action.payload.data);
    },
    editFailed: (state, action) => {
      state.creating = false;
      state.error = null;
    },

    toggleActive: (state, action) => {
      state.toggleLoading = true;
      state.error = null;
    },
    toggleActiveSuccess: (state, action) => {
      state.toggleLoading = false;
      state.error = null;
      const index = state.data.findIndex((item) => item.id === action.payload.id);
      state.data[index].active = action.payload.active;
    },
    toggleActiveFailed: (state, action) => {
      state.toggleLoading = false;
      state.error = null;
    },
  },
});

const { actions, reducer } = merchantsSlice;

const getMerchants = (state) => state.merchants.data;
const getSingleMerchant = (state) => state.merchants.single;
const getIsLoading = (state) => state.merchants.loading;
const getIsCreating = (state) => state.merchants.creating;
const getToggleLoading = (state) => state.merchants.toggleLoading;

const selectors = {
  data: createSelector(getMerchants, (data) => data),
  getSingleMerchant: createSelector(getSingleMerchant, (data) => data),
  isLoading: createSelector(getIsLoading, (data) => data),
  isCreating: createSelector(getIsCreating, (data) => data),
  toggleLoading: createSelector(getToggleLoading, (data) => data),
};

const getOne = (merchantId) => async (dispatch) => {
  dispatch(actions.getOne());
  try {
    const response = await MerchantApi.getOne(merchantId);
    if (response.success || response.status === 200) {
      dispatch(actions.getOneSuccess(response));
      return response.data;
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Get All Tag Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getOneFailed(error.message));
    dispatch(
      enqueueSnackbar({
        message: 'Cannot get merchants.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
    return error;
  }
};

const getAll = (query) => async (dispatch) => {
  dispatch(actions.getAll());
  try {
    const response = await MerchantApi.getAll('merchant-list', query);
    if (response.success || response.status === 200) {
      dispatch(actions.getAllSuccess(response));
      return {
        data: response.data,
        page: response.current_page - 1,
        totalCount: response.total,
      };
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Get All Merchant Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getAllFailed(error.message));
    dispatch(
      enqueueSnackbar({
        message: 'Cannot get merchants.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
    return error;
  }
};

const create = (query) => async (dispatch) => {
  dispatch(actions.create());
  try {
    const response = await MerchantApi.create(query);
    if (response.success || response.status === 200) {
      dispatch(actions.createSuccess(response));
      dispatch(
        enqueueSnackbar({
          message: 'Merchant created succesfully.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
      download(
        `${response.data.id} - ${response.data.name}`,
        `${response.data.id} - ${response.data.name} - ${response.data.username} - ${response.data.password}`,
      );
      return response;
    } else {
      const errMsg =
        response?.data?.errors &&
        Object.keys(response?.data?.errors || {})
          ?.map((er) => response?.data?.errors[er])
          ?.join('\n');
      dispatch(
        enqueueSnackbar({
          message: `${response.data.message} - ${errMsg}`,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );
      dispatch(actions.createFailed(response.data.message));
      return { error: true };
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.createFailed(error.message));
    dispatch(
      enqueueSnackbar({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
    return { error: true };
  }
};

const edit = (id, formData) => async (dispatch) => {
  dispatch(actions.edit());
  try {
    const response = await MerchantApi.edit(id, formData);
    if (response.success || response.status === 200) {
      dispatch(actions.editSuccess(response));
      dispatch(
        enqueueSnackbar({
          message: 'Merchant edit successful.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
      return response;
    } else {
      const errMsg =
        response?.data?.errors &&
        Object.keys(response?.data?.errors || {})
          ?.map((er) => response?.data?.errors[er])
          ?.join('\n');
      dispatch(
        enqueueSnackbar({
          message: `${response.data.message} - ${errMsg}`,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );
      dispatch(actions.createFailed(response.data.message));
      return { error: true };
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.editFailed(error.message));
    dispatch(
      enqueueSnackbar({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
    return error;
  }
};

const toggleActive = (id) => async (dispatch) => {
  dispatch(actions.toggleActive());
  try {
    const response = await MerchantApi.toggleActive(id);
    if (response.success || response.status === 200) {
      dispatch(actions.toggleActiveSuccess({ id, ...response }));
      return response;
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Get Merchants Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.toggleActiveFailed());
    dispatch(
      enqueueSnackbar({
        message: 'Something went wrong.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
    return error;
  }
};

export default {
  actions,
  reducer,
  selectors,
  getOne,
  getAll,
  create,
  edit,
  toggleActive,
};
