import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import routes from '../lib/routes';

import Logo from '../assets/logo.png';
import DrawerClose from '../icons/DrawerClose';

import DesktopDrawer from './DesktopDrawer';
import MobileDrawer from './MobileDrawer';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import LogoutModal from './LogOutModle';
import { useSelector } from 'react-redux';
import { el } from 'date-fns/locale';
import hasPermission from 'utils/adminPermission/hasPermission';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    background: '#002447',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  menuLink: {
    color: '#038FDD',
    fontWeight: 'bold',
  },
  activeLink: {
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
  logo: {
    display: 'block',
    width: '140px',
    marginLeft: '15px',
  },
}));

function SidebarItems({ handleDrawerClose, location, handleDrawerToggle }) {
  const [modalOpen, setModalOpen] = React.useState(false);
  let myRole = useSelector((state) => state.auth.data.role);

  const handleLogOutModleOpen = () => {
    setModalOpen(true);
  };
  const handleLogOutModleClose = () => {
    setModalOpen(false);
  };

  const classes = useStyles();

  const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const closeDrawer = () => {
    if (!matches) {
      handleDrawerClose();
    }
  };

  let updateRoutes = [];
  if (routes) {
    routes.map((el) => {
      if (el.permission !== undefined) {
        let checkPermission = hasPermission(el.permission);
        if (checkPermission) {
          updateRoutes.push(el);
        }
      } else {
        updateRoutes.push(el);
      }
    });
  }

  return (
    <>
      <div className={classes.toolbar}>
        <IconButton onClick={handleDrawerToggle}>
          <DrawerClose width={25} height={25} fill="#fff" />
        </IconButton>
        <img src={Logo} alt="Logo" className={classes.logo} />
      </div>
      <Divider />
      <List>
        {updateRoutes?.map(({ path, Icon, name }, index) => (
          <ListItem component={Link} to={path} key={path} onClick={closeDrawer}>
            <ListItemIcon>
              <Icon
                style={{
                  color: location.pathname === path ? '#FFFFFF' : '#038FDD',
                  width: 30,
                  height: 30,
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary={name}
              classes={{
                primary: location.pathname === path ? classes.activeLink : classes.menuLink,
              }}
            />
          </ListItem>
        ))}

        <ListItem component={Link} to={''} onClick={handleLogOutModleOpen}>
          <ListItemIcon>
            <ExitToAppIcon
              style={{
                color: '#fff',
                width: 30,
                height: 30,
              }}
            />
          </ListItemIcon>
          <ListItemText primary="Log Out" classes={{ primary: classes.activeLink }} />
        </ListItem>
      </List>
      <LogoutModal handleLogOutModleClose={handleLogOutModleClose} modalOpen={modalOpen} />
    </>
  );
}

export default function Sidebar({ open, handleDrawerClose, location, handleDrawerToggle }) {
  return (
    <>
      <Hidden only={['xl', 'lg', 'md']}>
        <MobileDrawer handleDrawerClose={handleDrawerClose} open={open}>
          <SidebarItems
            location={location}
            handleDrawerClose={handleDrawerClose}
            handleDrawerToggle={handleDrawerToggle}
          />
        </MobileDrawer>
      </Hidden>
      <Hidden only={['sm', 'xs']}>
        <DesktopDrawer handleDrawerClose={handleDrawerClose} open={open}>
          <SidebarItems
            location={location}
            handleDrawerClose={handleDrawerClose}
            handleDrawerToggle={handleDrawerToggle}
          />
        </DesktopDrawer>
      </Hidden>
    </>
  );
}
