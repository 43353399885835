import SuperFetch, { SuperUploader } from '../api/SuperFetch';
import querparser from 'query-string';

class Vouchers {
  getAll(query = { limit: 10, page: 1 }) {
    return SuperFetch(`voucher?${querparser.stringify(query)}`);
  }

  getAllLocations(query = { limit: 10, page: 1 }) {
    return SuperFetch(`voucher/tags?${querparser.stringify(query)}`);
  }

  create(query, formData) {
    return SuperUploader(`voucher?${querparser.stringify(query)}`, {
      body: formData,
    });
  }

  edit(id, query, formData) {
    return SuperUploader(`voucher/${id}?${querparser.stringify(query)}`, {
      body: formData,
    });
  }

  remove(id) {
    return SuperFetch(`voucher/${id}`, {
      method: 'DELETE',
    });
  }

  makeTrending(id) {
    return SuperFetch(`hats/voucher/trending?voucher_id=${id}`, {
      method: 'POST',
    });
  }

  removeTrending(id) {
    return SuperFetch(`hats/voucher/trending?voucher_id=${id}`, {
      method: 'DELETE',
    });
  }
  toggleTranding(id, query) {
    return SuperFetch(`voucher/${id}/toggle-trending?is_trending=${query}`, {
      method: 'POST',
    });
  }

  voucherStatus(id, status) {
    return SuperFetch(`voucher/${id}/toggle-status?status=${status}`, {
      method: 'POST',
    });
  }
}

export default new Vouchers();
