import SuperFetch from 'api/SuperFetch';
import querparser from 'query-string';

class Merchants {
  getOne(key, id) {
    return SuperFetch(`hats/merchant/${id}`);
  }

  getAll(key, query = { limit: 10, page: 1, sort: 'id', order: 'desc' }) {
    return SuperFetch(
      `hats/merchant?${querparser.stringify(query, {
        skipEmptyString: true,
        skipNull: true,
      })}`,
    );
  }

  create(value) {
    return SuperFetch(`hats/merchant`, {
      method: 'POST',
      data: value,
    });
  }

  edit(id, body) {
    return SuperFetch(`hats/merchant/${id}`, {
      method: 'POST',
      data: body,
    });
  }

  toggleActive(id) {
    return SuperFetch(`hats/merchant/${id}/switch-status`);
  }

  getMerchantJoinRequest(query = { limit: 20 }) {
    return SuperFetch(`hats/new-merchants?${querparser.stringify(query)}`);
  }

  changePassword(id, pass) {
    return SuperFetch(`hats/merchant/${id}/change-password?password=${pass}`, { method: 'POST' });
  }
}

export default new Merchants();
