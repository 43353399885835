import { createSlice, createSelector } from '@reduxjs/toolkit';
import Customers from '../repositories/Customers';
import { enqueueSnackbar } from './notifications';

const customersSlice = createSlice({
  name: 'customers',
  initialState: {
    loading: false,
    error: null,
    data: [],
    details: {
      referred_by: false,
      refers: [],
      claims: [],
    },
    detailLoading: false,
    associatLoading: false,
    single: {},
    singleLoading: false,
  },
  reducers: {
    // GET All Customers
    getAll: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    getAllSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
    },
    getAllFailed: (state, action) => {
      state.loading = false;
      state.error = true;
    },
    // Get Customer Details
    getDetails: (state, action) => {
      state.detailLoading = true;
      state.error = null;
    },
    getDetailsSuccess: (state, action) => {
      state.detailLoading = false;
      state.error = null;
      state.details = action.payload.data;
    },
    getDetailsFailed: (state, action) => {
      state.detailLoading = false;
      state.error = true;
    },

    // Get Customer Details
    getSingle: (state, action) => {
      state.detailLoading = true;
      state.error = null;
    },
    getSingleSuccess: (state, action) => {
      state.detailLoading = false;
      state.error = null;
      state.single = action.payload.data;
    },
    getSingleFailed: (state, action) => {
      state.detailLoading = false;
      state.error = true;
    },
    getAssociatAccount: (state, action) => {
      state.associatLoading = true;
      state.error = null;
    },
    getAssociatAccountSuccess: (state, action) => {
      state.associatLoading = false;
      state.error = null;
      state.associateAccount = action.payload.data;
    },
    getAssociatAccountFailed: (state, action) => {
      state.associatLoading = false;
      state.error = true;
    },
  },
});

const { actions, reducer } = customersSlice;

const getCustomers = (state) => state.customers.data;
const getIsLoading = (state) => state.customers.loading;
const getDetailsLoading = (state) => state.customers.detailLoading;
const customerDetails = (state) => state.customers.details;
const getSingleLoading = (state) => state.customers.singleLoading;
const singleCustomer = (state) => state.customers.single;
const getIsAssociatLoading = (state) => state.customers.associatLoading;
const associateAccount = (state) => state.customers.associateAccount;

const selectors = {
  data: createSelector(getCustomers, (data) => data),
  isLoading: createSelector(getIsLoading, (data) => data),
  details: createSelector(customerDetails, (data) => data),
  detailsLoading: createSelector(getDetailsLoading, (data) => data),
  single: createSelector(singleCustomer, (data) => data),
  singleLoading: createSelector(getSingleLoading, (data) => data),
  associatLoading: createSelector(getIsAssociatLoading, (data) => data),
  associate: createSelector(associateAccount, (data) => data),
};

const getAll = (query) => async (dispatch) => {
  dispatch(actions.getAll());
  try {
    const response = await Customers.getAll(query);
    if (response.success || response.status === 200) {
      dispatch(actions.getAllSuccess(response));
      return {
        data: response.data,
        page: response.current_page - 1,
        totalCount: response.total,
      };
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Get All Customer Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getAllFailed(error.message));
    dispatch(
      enqueueSnackbar({
        message: 'Cannot get customers.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
    return error;
  }
};

const getDetails = (query) => async (dispatch) => {
  dispatch(actions.getDetails());
  try {
    const response = await Customers.getDetails(query);
    if (response.success || response.status === 200) {
      dispatch(actions.getDetailsSuccess(response));
      return response.data;
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Get Customer Details Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getDetailsFailed(error.message));
    dispatch(
      enqueueSnackbar({
        message: 'Cannot get customers details.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
    return error;
  }
};

const getSingle = (query) => async (dispatch) => {
  dispatch(actions.getSingle());
  try {
    const response = await Customers.getSingle(query);
    if (response.success || response.status === 200) {
      dispatch(actions.getSingleSuccess(response));
      return response.data;
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Get Customer Details Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getSingleFailed(error.message));
    dispatch(
      enqueueSnackbar({
        message: 'Cannot get customers details.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
    return error;
  }
};

const getAssociatAccount = (query) => async (dispatch) => {
  dispatch(actions.getAssociatAccount());
  try {
    const response = await Customers.getAssociatAccount(query);
    if (response.success || response.status === 200) {
      dispatch(actions.getAssociatAccountSuccess(response));
      return response.data;
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Get Customer Associat Account Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getAssociatAccountFailed(error.message));
    dispatch(
      enqueueSnackbar({
        message: 'Cannot get Customers Associat Account details.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
    return error;
  }
};

export default {
  actions,
  reducer,
  selectors,
  getAll,
  getDetails,
  getSingle,
  getAssociatAccount,
};
