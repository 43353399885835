import { createSlice, createSelector } from '@reduxjs/toolkit';
import Blog from 'repositories/Blog';
import { enqueueSnackbar } from './notifications';

const blogSlice = createSlice({
  name: 'blog',
  initialState: {
    loading: false,
    responding: false,
    error: null,
    data: [],
    blogImage: null,
  },
  reducers: {
    getAll: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    getAllSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
    },
    getAllFailed: (state, action) => {
      state.loading = false;
      state.error = null;
    },

    getSingleBlog: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    getSingleBlogSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
    },
    getSingleBlogFailed: (state, action) => {
      state.loading = false;
      state.error = null;
    },
    getAllTag: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    getAllTagSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
    },
    getAllTagFailed: (state, action) => {
      state.loading = false;
      state.error = null;
    },

    createBlog: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    createBlogSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
    },
    createBlogFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    updateBlog: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    updateBlogSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
    },
    updateBlogFailed: (state, action) => {
      state.loading = false;
      state.error = null;
    },

    updateBlogImageSuccess: (state, action) => {
      state.blogImage = action.payload;
    },

    deleteBlog: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    deleteBlogSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
    },
    deleteBlogFailed: (state, action) => {
      state.loading = false;
      state.error = null;
    },
  },
});

const { actions, reducer } = blogSlice;

const getBlogs = (state) => state.blog.data;
const getIsLoading = (state) => state.blog.loading;

const selectors = {
  data: createSelector(getBlogs, (data) => data),
  isLoading: createSelector(getIsLoading, (data) => data),
};

const getAll = (query) => async (dispatch) => {
  dispatch(actions.getAll());
  try {
    const response = await Blog.getAll(query);
    if (response.success || response.status === 200) {
      dispatch(actions.getAllSuccess(response));
      return {
        data: response.data,
        page: response.current_page - 1,
        totalCount: response.total,
      };
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Get All Blogs Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getAllFailed(error.message));
    return error;
  }
};
const getAllTag = (query) => async (dispatch) => {
  dispatch(actions.getAllTag());
  try {
    const response = await Blog.getAllTag(query);
    if (response.success || response.status === 200) {
      dispatch(actions.getAllTagSuccess(response));
      return {
        data: response.data,
        page: response.current_page - 1,
        totalCount: response.total,
      };
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Get All Blog Tags Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getAllTagFailed(error.message));
    return error;
  }
};

const createBlog = (query, formData) => async (dispatch) => {
  dispatch(actions.createBlog());
  try {
    const response = await Blog.createBlog(query, formData);

    if (response.success || response.status === 200) {
      dispatch(actions.createBlogSuccess(response));
      dispatch(
        enqueueSnackbar({
          message: 'Blog Created succesfully.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
      return {
        ...response,
      };
    } else {
      throw Error(response.errMgs ? response.errMgs : ' Blog Create Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.createBlogFailed(error.message));
    dispatch(
      enqueueSnackbar({
        message: 'Failed to create blog.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
    return error;
  }
};

const updateBlog = (id, query, formData) => async (dispatch) => {
  dispatch(actions.updateBlog());
  try {
    const response = await Blog.updateBlog(id, query, formData);

    if (response.success || response.status === 200) {
      dispatch(actions.updateBlogSuccess(response));
      dispatch(
        enqueueSnackbar({
          message: 'Blog Update succesfully.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
      return {
        ...response,
      };
    } else {
      throw Error(response.errMgs ? response.errMgs : ' Blog Update Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.updateBlogFailed(error.message));
    dispatch(
      enqueueSnackbar({
        message: 'Failed to create blog.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
    return error;
  }
};

const deleteBlog = (id) => async (dispatch) => {
  dispatch(actions.deleteBlog());
  try {
    const response = await Blog.deleteBlog(id);
    if (response.success || response.status === 200) {
      dispatch(actions.deleteBlogSuccess(response));
      dispatch(
        enqueueSnackbar({
          message: 'Blog deleted succesfully.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
      return {
        ...response,
      };
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Blog Delete Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.deleteBlogFailed(error.message));
    dispatch(
      enqueueSnackbar({
        message: 'Failed to delete banner.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
    return error;
  }
};

export default {
  actions,
  reducer,
  selectors,
  getAll,
  getAllTag,
  createBlog,
  updateBlog,
  deleteBlog,
};
