import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import StorefrontOutlinedIcon from '@material-ui/icons/StorefrontOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import CardGiftcardOutlinedIcon from '@material-ui/icons/CardGiftcardOutlined';
import CardMembershipOutlinedIcon from '@material-ui/icons/CardMembershipOutlined';
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import MobileScreenShareOutlinedIcon from '@material-ui/icons/MobileScreenShareOutlined';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import SettingsIcon from '@material-ui/icons/Settings';
import RateReviewOutlinedIcon from '@material-ui/icons/RateReviewOutlined';
import AddPhotoAlternateOutlinedIcon from '@material-ui/icons/AddPhotoAlternateOutlined';
import SportsEsportsOutlinedIcon from '@material-ui/icons/SportsEsportsOutlined';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import Assessment from '@material-ui/icons/Assessment';
import BookIcon from '@material-ui/icons/Book';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';
import StorefrontIcon from '@material-ui/icons/Storefront';
import SwapCallsIcon from '@material-ui/icons/SwapCalls';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import PaymentIcon from '@material-ui/icons/Payment';
import CodeIcon from '@material-ui/icons/Code';
import RateReviewIcon from '@material-ui/icons/RateReview';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import permissions from 'utils/adminPermission/permissions';
import Add from '@material-ui/icons/Add';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PhonelinkEraseIcon from '@material-ui/icons/PhonelinkErase';
import ScoreIcon from '@material-ui/icons/Score';
export default [
  {
    name: 'Overview',
    path: '/',
    Icon: AssessmentOutlinedIcon,
    permission: [permissions.dashboard.read],
  },
  {
    name: 'Merchants',
    path: '/merchants',
    Icon: StorefrontOutlinedIcon,
    permission: [
      permissions.merchants.read,
      permissions.merchants.create,
      permissions.merchants.delete,
      permissions.merchants.update,
    ],
  },
  {
    name: 'Customers',
    path: '/customers',
    Icon: PeopleAltOutlinedIcon,
    permission: [
      permissions.customers.read,
      permissions.customers.create,
      permissions.customers.delete,
      permissions.customers.update,
    ],
  },

  {
    name: 'Mobile Recharge',
    path: '/mobile-recharge',
    Icon: MobileScreenShareOutlinedIcon,
    permission: [
      permissions.topups.read,
      permissions.topups.create,
      permissions.topups.delete,
      permissions.topups.update,
    ],
  },

  {
    name: 'TopUp Unsuccess',
    path: '/topup-failed',
    Icon: PhonelinkEraseIcon,
    permission: [permissions.topups.read],
  },

  {
    name: 'Points',
    path: '/points',
    Icon: AccountBalanceWalletOutlinedIcon,
    permission: [
      permissions.wallets.read,
      permissions.wallets.create,
      permissions.wallets.delete,
      permissions.wallets.update,
    ],
  },

  {
    name: 'Points Claim',
    path: '/cashbacks',
    Icon: MonetizationOnOutlinedIcon,
    permission: [
      permissions.bill_claims.read,
      permissions.bill_claims.create,
      permissions.bill_claims.delete,
      permissions.bill_claims.update,
    ],
  },
  {
    name: 'Reviews',
    path: '/reviews',
    Icon: RateReviewOutlinedIcon,
    permission: [
      permissions.reviews.read,
      permissions.reviews.create,
      permissions.reviews.delete,
      permissions.reviews.update,
    ],
  },

  {
    name: 'Vouchers',
    path: '/vouchers',
    Icon: CardGiftcardOutlinedIcon,
    permission: [
      permissions.vouchers.read,
      permissions.vouchers.create,
      permissions.vouchers.delete,
      permissions.vouchers.update,
    ],
  },
  {
    name: 'Voucher Requests',
    path: '/voucher-requests',
    Icon: CardMembershipOutlinedIcon,
    permission: [
      permissions.voucher_requests.read,
      permissions.voucher_requests.create,
      permissions.voucher_requests.delete,
      permissions.voucher_requests.update,
    ],
  },
  // {
  //   name: 'Voucher Code',
  //   path: '/voucher-code',
  //   Icon: CodeIcon,
  // },

  {
    name: 'Notice',
    path: '/notice',
    Icon: AddPhotoAlternateOutlinedIcon,
    permission: [
      permissions.notice.read,
      permissions.notice.create,
      permissions.notice.delete,
      permissions.notice.update,
    ],
  },

  {
    name: 'Banners',
    path: '/banners',
    Icon: ViewCarouselIcon,
  },

  {
    name: 'Games',
    path: '/games',
    Icon: SportsEsportsOutlinedIcon,
    permission: [
      permissions.games.read,
      permissions.games.create,
      permissions.games.delete,
      permissions.games.update,
    ],
  },
  {
    name: 'Survey',
    path: '/survey',
    Icon: Assessment,
    permission: [
      permissions.games.read,
      permissions.games.create,
      permissions.games.delete,
      permissions.games.update,
    ],
  },
  {
    name: 'Ads Banner',
    path: '/ads-banner',
    Icon: AddPhotoAlternateIcon,
  },
  {
    name: 'Communication',
    path: '/communication',
    Icon: SwapCallsIcon,
    permission: [
      permissions.communications.read,
      permissions.communications.create,
      permissions.communications.delete,
      permissions.communications.update,
    ],
  },
  {
    name: 'Gift Reason',
    path: '/gift-reason',
    Icon: CardGiftcardIcon,
    permission: [
      permissions.wallets.read,
      permissions.wallets.create,
      permissions.wallets.delete,
      permissions.wallets.update,
    ],
  },

  {
    name: 'Merchant Joining Request',
    path: '/merchant-join-request',
    Icon: ThumbsUpDownIcon,
  },
  {
    name: 'Wheel Merchant',
    path: '/wheel-merchant',
    Icon: StorefrontIcon,
  },
  {
    name: 'Tags',
    path: '/tags',
    Icon: LocalOfferOutlinedIcon,
  },
  {
    name: 'Categories',
    path: '/categories',
    Icon: CategoryOutlinedIcon,
    permission: [
      permissions.categories.read,
      permissions.categories.create,
      permissions.categories.delete,
      permissions.categories.update,
    ],
  },
  {
    name: 'Blog',
    path: '/blogs',
    Icon: BookIcon,
    permission: [
      permissions.blog.read,
      permissions.blog.create,
      permissions.blog.delete,
      permissions.blog.update,
    ],
  },
  {
    name: 'Payments',
    path: '/payments',
    Icon: PaymentIcon,
    permission: [permissions.wallets.read],
  },
  {
    name: 'Merchant Payments',
    path: '/metchat-payments',
    Icon: AccountBalanceIcon,
  },
  {
    name: 'Playstore Review ',
    path: '/psreview',
    Icon: RateReviewIcon,
    permission: [permissions.reviews.read, permissions.reviews.delete],
  },

  {
    name: 'Admin Management',
    path: '/admin-management',
    Icon: AccessibilityIcon,
    permission: [
      permissions.admin_management.read,
      permissions.admin_management.create,
      permissions.admin_management.delete,
      permissions.admin_management.update,
    ],
  },

  {
    name: 'Happicard Waitlist',
    path: '/happicard_waitlists',
    Icon: ListAltIcon,
  },

  {
    name: 'Credit Scoring',
    path: '/credit_scoring',
    Icon: ScoreIcon,
    permission: [
      permissions.merchants.read,
      permissions.merchants.create,
      permissions.merchants.delete,
      permissions.merchants.update,
    ],
  },

  {
    name: 'Settings',
    path: '/settings',
    Icon: SettingsIcon,
  },
];
