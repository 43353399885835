import { createSlice, createSelector } from '@reduxjs/toolkit';
import Categories from '../repositories/Categories';
import { enqueueSnackbar } from './notifications';

const categorySlice = createSlice({
  name: 'categories',
  initialState: {
    loading: false,
    error: null,
    data: [],
  },
  reducers: {
    getAll: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    getAllSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
    },
    getAllFailed: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    create: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    createSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data.push(action.payload.data);
    },
    createFailed: (state, action) => {
      state.loading = false;
      state.error = null;
    },
    edit: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    editSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = state.data.map(item => {
        if (item.id === action.payload.id) {
          return action.payload;
        }
        return item;
      });
    },
    editFailed: (state, action) => {
      state.loading = false;
      state.error = null;
    },
  },
});

const { actions, reducer } = categorySlice;

const getCategories = state => state.categories.data;
const getIsLoading = state => state.categories.loading;
const getSingleMerchant = state => state.merchants.single;

const dataSelector = createSelector(
  getCategories,
  data => data,
);
const isLoadingSelector = createSelector(
  getIsLoading,
  data => data,
);
const optionsSelector = createSelector(
  getCategories,
  data => data.map(({ id, name }) => ({ value: id, label: name })),
);

const currentSelector = createSelector(
  optionsSelector,
  getSingleMerchant,
  (list, merchant) => list.filter(({ id }) => id === merchant.category_id),
);

const selectors = {
  data: dataSelector,
  isLoading: isLoadingSelector,
  selectOptions: optionsSelector,
  selected: currentSelector,
};

const getAll = query => async dispatch => {
  dispatch(actions.getAll());
  try {
    const response = await Categories.getAll(query);
    if (response.status === 200) {
      dispatch(actions.getAllSuccess(response));
      return {
        data: response.data,
        page: response.current_page - 1,
        totalCount: response.total,
      };
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Get All Categories Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getAllFailed(error.message));
    return error;
  }
};

const create = values => async (dispatch, getState) => {
  dispatch(actions.create());
  try {
    const response = await Categories.create(values);
    if (response.success) {
      dispatch(actions.createSuccess(response));
      dispatch(
        enqueueSnackbar({
          message: 'Category Created Succesfully.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Create Category Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.createFailed(error.message));
  }
};

const edit = (id, values) => async (dispatch, getState) => {
  dispatch(actions.edit());
  try {
    const response = await Categories.edit(id, values);
    if (response.success) {
      dispatch(actions.editSuccess(response.data));
      dispatch(
        enqueueSnackbar({
          message: 'Category Edited Succesfully.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Category Edit Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.editFailed(error.message));
  }
};

export default {
  actions,
  reducer,
  selectors,
  getAll,
  create,
  edit,
};
