import { combineReducers } from 'redux';

import auth from 'modules/auth';
import notistack from 'modules/notifications';
import Categories from 'modules/categories';
import Tags from 'modules/tags';
import Customers from 'modules/customers';
import Merchants from 'modules/merchants';
import Cashbacks from 'modules/cashbacks';
import Vouchers from 'modules/vouchers';
import Reviews from 'modules/reviews';
import VoucherRequests from 'modules/voucherRequests';
import Topups from 'modules/topup';
import Wallet from 'modules/wallet';
import Payments from 'modules/payments';
import Banners from 'modules/banners';
import Notices from 'modules/notices';
import Quizzes from 'modules/quizzes';
import Survey from 'modules/survey';
import Blog from 'modules/blog';
import wheels from 'modules/wheels';
import happicardWaitList from 'modules/happicardWaitList';
import psReviews from 'modules/psReviews';
import creditScoring from 'modules/creditScoring';
import adminManagement from 'modules/adminManagement';
import wheelerMerchants from 'modules/wheelerMerchants';

export default combineReducers({
  auth,
  notistack,
  categories: Categories.reducer,
  tags: Tags.reducer,
  customers: Customers.reducer,
  merchants: Merchants.reducer,
  cashbacks: Cashbacks.reducer,
  vouchers: Vouchers.reducer,
  reviews: Reviews.reducer,
  psReviews: psReviews.reducer,
  wheels: wheels.reducer,
  voucherRequests: VoucherRequests.reducer,
  topup: Topups.reducer,
  wallet: Wallet.reducer,
  payments: Payments.reducer,
  banners: Banners.reducer,
  notices: Notices.reducer,
  quizzes: Quizzes.reducer,
  survey: Survey.reducer,
  blog: Blog.reducer,
  creditScoring: creditScoring.reducer,
  happicardWaitList: happicardWaitList.reducer,
  adminManagement: adminManagement.reducer,
  wheelerMerchants: wheelerMerchants.reducer,
});
