import SuperFetch from '../api/SuperFetch';
import querparser from 'query-string';

class CreditScoringRepo {
  getAll(query = { limit: 20, page: 1 }) {
    return SuperFetch(`hats/customer/customer-score?${querparser.stringify(query)}`);
  }
}

export default new CreditScoringRepo();
