import { createSlice, createSelector } from '@reduxjs/toolkit';
import Banners from '../repositories/Banners';
import { enqueueSnackbar } from './notifications';

const bannersSlice = createSlice({
  name: 'banners',
  initialState: {
    loading: false,
    responding: false,
    error: null,
    data: [],
  },
  reducers: {
    getAll: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    getAllSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
    },
    getAllFailed: (state, action) => {
      state.loading = false;
      state.error = null;
    },

    createBanner: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    createBannerSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
    },
    createBannerFailed: (state, action) => {
      state.loading = false;
      state.error = null;
    },

    updateBanner: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    updateBannerSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
    },
    updateBannerFailed: (state, action) => {
      state.loading = false;
      state.error = null;
    },

    deleteBanner: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    deleteBannerSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
    },
    deleteBannerFailed: (state, action) => {
      state.loading = false;
      state.error = null;
    },
  },
});

const { actions, reducer } = bannersSlice;

const getBanners = (state) => state.banners.data;
const getIsLoading = (state) => state.banners.loading;

const selectors = {
  data: createSelector(getBanners, (data) => data),
  isLoading: createSelector(getIsLoading, (data) => data),
};

const getAll = (query) => async (dispatch) => {
  dispatch(actions.getAll());
  try {
    const response = await Banners.getAll(query);
    if (response.success || response.status === 200) {
      dispatch(actions.getAllSuccess(response));
      return {
        data: response.data,
        page: response.current_page - 1,
        totalCount: response.total,
      };
    } else {
      throw Error(response.message ? response.message : 'Get All Banners Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getAllFailed(error.message));
    return error;
  }
};

const createBanner = (query, formData) => async (dispatch) => {
  dispatch(actions.createBanner());
  try {
    const response = await Banners.createBanner(query, formData);
    if (response.success || response.status === 200) {
      dispatch(actions.createBannerSuccess(response));
      dispatch(
        enqueueSnackbar({
          message: 'Banner uploaded succesfully.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
      return { ...response };
    } else {
      const e = new Error(response.message);
      e.response = response;
      throw e;
    }
  } catch (error) {
    dispatch(actions.createBannerFailed(error.message));
    dispatch(
      enqueueSnackbar({
        message: 'Failed to upload banner.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
    return error.response;
  }
};

const updateBanner = (id, query, formData) => async (dispatch) => {
  dispatch(actions.updateBanner());
  try {
    const response = await Banners.updateBanner(id, query, formData);
    if (response.success || response.status === 200) {
      dispatch(actions.updateBannerSuccess(response));
      return { ...response };
    } else {
      throw Error(response.message ? response.message : 'Banner Create Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.updateBannerFailed(error.message));
    return error;
  }
};

const deleteBanner = (query, formData) => async (dispatch) => {
  dispatch(actions.deleteBanner());
  try {
    const response = await Banners.deleteBanner(query, formData);
    if (response.success || response.status === 200) {
      dispatch(actions.deleteBannerSuccess(response));
      dispatch(
        enqueueSnackbar({
          message: 'Banner deleted succesfully.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
      return { ...response };
    } else {
      throw Error(response.message ? response.message : 'Get All Banners Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.deleteBannerFailed(error.message));
    dispatch(
      enqueueSnackbar({
        message: 'Failed to delete banner.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
    return error;
  }
};

export default {
  actions,
  reducer,
  selectors,
  getAll,
  createBanner,
  deleteBanner,
  updateBanner,
};
