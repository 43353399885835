import { createSlice, createSelector } from '@reduxjs/toolkit';
import VoucherRequestsRepo from 'repositories/VoucherRequestsRepo';

import { enqueueSnackbar } from './notifications';

const voucherRequestsSlice = createSlice({
  name: 'voucherRequests',
  initialState: {
    loading: false,
    error: null,
    data: [],
  },
  reducers: {
    getAll: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    getAllSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
    },
    getAllFailed: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    accept: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    acceptSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      // state.data.push(action.payload.data);
    },
    acceptFailed: (state, action) => {
      state.loading = false;
      state.error = null;
    },
  },
});

const { actions, reducer } = voucherRequestsSlice;

const getVouchers = (state) => state.voucherRequests.data;
const getIsLoading = (state) => state.voucherRequests.loading;

const selectors = {
  data: createSelector(getVouchers, (data) => data),
  isLoading: createSelector(getIsLoading, (data) => data),
};

const getAll = (query) => async (dispatch) => {
  dispatch(actions.getAll());
  try {
    const response = await VoucherRequestsRepo.getAll(query);
    if (response.success || response.status === 200) {
      dispatch(actions.getAllSuccess(response));
      return {
        data: response.data,
        page: response.current_page - 1,
        totalCount: response.total,
      };
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Get All Voucher Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getAllFailed(error.message));
    return error;
  }
};

const accept = (id) => async (dispatch, getState) => {
  dispatch(actions.accept());
  try {
    const response = await VoucherRequestsRepo.accept(id);
    if (response.success && response.data !== null) {
      dispatch(actions.acceptSuccess(response));
      dispatch(
        enqueueSnackbar({
          message: 'Voucher Accepted Succesfully.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Voucher Accept Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.acceptFailed(error.message));
    dispatch(
      enqueueSnackbar({
        message: 'Voucher Accept Failed.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
  }
};

export default {
  actions,
  reducer,
  selectors,
  getAll,
  accept,
};
