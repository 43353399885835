import SuperFetch, { SuperMallFetch, SuperUploader } from '../api/SuperFetch';
import querparser from 'query-string';

class Topups {
  getAll(query = { limit: 10, page: 1 }) {
    return SuperFetch(
      `hats/topups?${querparser.stringify(query, {
        skipEmptyString: true,
        skipNull: true,
      })}`,
    );
  }

  getAllOperator(query = { limit: 10, page: 1 }) {
    return SuperFetch(
      `hats/topups/topup-config?${querparser.stringify(query, {
        skipEmptyString: true,
        skipNull: true,
      })}`,
    );
  }

  edit(id, body) {
    console.log('body', body);
    return SuperFetch(`hats/topup/${id}/update`, { method: 'POST', data: body });
  }

  updateStatus(id, body) {
    return SuperFetch(`hats/topup/${id}/refund-status`, { method: 'POST', data: body });
  }

  getAllStatus() {
    const topupStatus = [
      {
        id: 1,
        status: null,
        label: 'All',
      },
      {
        id: 2,
        status: 'Processing',
        label: 'Processing',
      },
      {
        id: 3,
        status: 'Success',
        label: 'Success',
      },
      {
        id: 4,
        status: 'Failed',
        label: 'Failed',
      },
    ];

    return topupStatus;
  }

  makeRetryTopup(id) {
    return SuperMallFetch(`admin/order/${id}/check-payment`, { method: 'POST' });
  }

  getExcel(query = { startDate: '', endDate: '', limit: 0, page: 0 }) {
    const { startDate, endDate, limit, page } = query;
    console.log(page);
    return SuperFetch(
      `hats/topups/history?start_date=${startDate}&end_date=${endDate}&limit=${limit}&page=${page}`,
    );
  }

  getFailedTopupUser(query = { currentDate: '' }) {
    const { currentDate } = query.dateRange;
    return SuperFetch(`hats/topup/failed/customers?date=${currentDate}`);
  }

  downloadCSV(fileName = 'topup-report.xlsx') {
    return SuperFetch(`hats/reports/export-topup-csv/${fileName}`);
  }
}

export default new Topups();
