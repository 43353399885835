import { createSlice, createSelector } from '@reduxjs/toolkit';
import Wallet from '../repositories/Wallet';
import { enqueueSnackbar } from './notifications';

const walletSlice = createSlice({
  name: 'wallet',
  initialState: {
    loading: false,
    responding: false,
    error: null,
    data: [],
    sending: false,
    systemBalance: 0,
    adminBalance: 0,
  },
  reducers: {
    getAll: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    getAllSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
    },
    getAllFailed: (state, action) => {
      state.loading = false;
      state.error = null;
    },

    getSystemBalance: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    getSystemBalanceSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.systemBalance = action.payload.balance;
    },
    getSystemBalanceFailed: (state, action) => {
      state.loading = false;
      state.error = true;
    },

    getAdminBalance: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    getAdminBalanceSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.adminBalance = action.payload.data.balance;
    },
    getAdminBalanceFailed: (state, action) => {
      state.loading = false;
      state.error = true;
    },

    sendGift: (state, action) => {
      state.sending = true;
      state.error = null;
    },
    sendGiftOk: (state, action) => {
      state.sending = false;
      state.error = null;
    },
    sendGiftSuccess: (state, action) => {
      state.sending = false;
      state.error = null;
      state.systemBalance -= action.payload.amount;
      state.data.unshift(action.payload);
    },
    sendGiftFailed: (state, action) => {
      state.sending = false;
      state.error = true;
    },
  },
});

const { actions, reducer } = walletSlice;

const getWallet = (state) => state.wallet.data;
const getIsLoading = (state) => state.wallet.loading;
const getIsSending = (state) => state.wallet.sending;

const selectors = {
  data: createSelector(getWallet, (data) => data),
  isLoading: createSelector(getIsLoading, (data) => data),
  isSending: createSelector(getIsSending, (data) => data),
  systemBalance: createSelector(
    (state) => state.wallet.systemBalance,
    (data) => data,
  ),
  adminBalance: createSelector(
    (state) => state.wallet.adminBalance,
    (data) => data,
  ),
};

const getAll = (query) => async (dispatch) => {
  dispatch(actions.getAll());
  try {
    const response = await Wallet.getAll(query);
    if (response.success || response.status === 200) {
      dispatch(actions.getAllSuccess(response));
      return {
        data: response.data,
        page: response.current_page - 1,
        totalCount: response.total,
      };
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Get Wallet Transactions Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getAllFailed(error.message));
    return {
      data: [],
      page: 0,
      totalCount: 0,
    };
  }
};
const getSystemBalance = () => async (dispatch) => {
  dispatch(actions.getSystemBalance());
  try {
    const response = await Wallet.getSystemBalance();
    if (response.success || response.status === 200) {
      dispatch(actions.getSystemBalanceSuccess(response));
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Get System Balance Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getSystemBalanceFailed(error.message));
    return error;
  }
};
const getAdminBalance = () => async (dispatch) => {
  dispatch(actions.getAdminBalance());
  try {
    const response = await Wallet.getAdminBalance();
    if (response.success || response.status === 200) {
      dispatch(actions.getAdminBalanceSuccess(response));
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Get Admin Balance Failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getAdminBalanceFailed(error.message));
    return error;
  }
};

const sendGift = (query) => async (dispatch) => {
  dispatch(actions.sendGift());
  try {
    const response = await Wallet.sendGift(query);

    if (response.success) {
      dispatch(actions.sendGiftOk(response));
      return { ...response };
    } else {
      throw Error(response.errMgs ? response.errMgs : 'Sending gift point failed');
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.sendGiftFailed(error.message));
    return error;
  }
};

export default {
  actions,
  reducer,
  selectors,
  getAll,
  getSystemBalance,
  getAdminBalance,
  sendGift,
};
