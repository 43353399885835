import SuperFetch, { SuperUploader } from '../api/SuperFetch';
import querparser from 'query-string';

class Notice {
  getAll(query = { limit: 10, page: 1 }) {
    return SuperFetch(`hats/notice?${querparser.stringify(query)}`);
  }

  createNotice(query, formData) {
    // console.log(formData);
    return SuperUploader(
      `hats/notice?${querparser.stringify(query, {
        skipEmptyString: true,
        skipNull: true,
      })}`,
      {
        body: formData,
        header: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  }

  deleteNotice(id) {
    return SuperFetch(`hats/notice/${id}`, {
      method: 'DELETE',
    });
  }
  pushNotice(id) {
    return SuperFetch(`hats/notice/${id}/push`, {
      method: 'POST',
    });
  }
}
export default new Notice();
