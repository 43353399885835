import SuperFetch, { SuperUploader } from '../api/SuperFetch';
import querparser from 'query-string';

class Blog {
  getAll(query = { limit: 10, page: 1 }) {
    return SuperFetch(`hats/blogs?${querparser.stringify(query)}`);
  }

  getSingleBlog(id) {
    return SuperFetch(`hats/blogs?${id}`);
  }
  getAllTag(query = { limit: 10, page: 1 }) {
    return SuperFetch(`hats/blog-tags?${querparser.stringify(query)}`);
  }

  createBlog(formData) {
    return SuperUploader(`hats/blogs`, {
      body: formData,
    });
  }

  updateBlog(id, formData) {
    return SuperUploader(`hats/blogs/${id}`, {
      body: formData,
      method: 'POST',
    });
  }

  deleteBlog(id) {
    return SuperFetch(`hats/blogs/${id}`, {
      method: 'DELETE',
    });
  }
}

export default new Blog();
