import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    background: '#003366',
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
}));

export default function MobileDrawer(props) {
  const { open, children, handleDrawerClose } = props;
  const classes = useStyles(props);

  return (
    <Drawer
      variant="temporary"
      open={open}
      onClose={handleDrawerClose}
      classes={{
        paper: classes.drawer,
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      {children}
    </Drawer>
  );
}
