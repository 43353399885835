import SuperFetch, { SuperMallFetch } from '../api/SuperFetch';
import querparser from 'query-string';
import { mallBaseUrl } from 'api/apiConfig';

class LuckyWheel {
  getAll(query = { limit: 10, page: 1 }) {
    return SuperFetch(`/hats/wheel?${querparser.stringify(query)}`);
  }

  getSpin(id) {
    return SuperFetch(`/hats/wheel-spin?wheel_id=${id}`);
  }

  create(data) {
    return SuperFetch(`/hats/wheel`, {
      method: 'POST',
      'Content-Type': 'multipart/form-data',
      data,
    });
  }

  assignSpins(data) {
    return SuperFetch('/hats/wheel-spin', {
      method: 'POST',
      'Content-Type': 'multipart/form-data',
      data: data,
    });
  }

  updateSingleSpin(id, data) {
    return SuperFetch(`/hats/wheel-spin/${id}`, {
      method: 'POST',
      'Content-Type': 'multipart/form-data',
      data: data,
    });
  }

  wheelStatusUpdate(id, data) {
    return SuperFetch(`/hats/wheel/${id}/status`, {
      method: 'POST',
      data: data,
    });
  }

  getAllMallProducts(query = { limit: 10, page: 1 }) {
    return SuperMallFetch(`${mallBaseUrl}/admin/product?${querparser.stringify(query)}`);
  }

  allReport(query = { limit: 20, page: 1 }) {
    return SuperFetch(`/hats/wheel/reports?${querparser.stringify(query)}`);
  }

  wheelReport(id) {
    return SuperFetch(`/hats/wheel/summary?wheel_id=${id}`);
  }
}

export default new LuckyWheel();
