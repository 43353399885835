import SuperFetch from '../api/SuperFetch';
import querparser from 'query-string';
import { API_ENDPOINTS } from 'utils/api/endpoints';

class Cashbacks {
  getAll(_key, query = { limit: 10, page: 1 }) {
    return SuperFetch(
      `hats/bills?${querparser.stringify(query, {
        skipEmptyString: true,
        skipNull: true,
      })}`,
    );
  }

  getOne(id) {
    return SuperFetch(`hats/bills/${id}`);
  }

  getAllProducts(query = { limit: 10, page: 1 }) {
    return SuperFetch(`hats/bill-products?${querparser.stringify(query)}`);
  }
  attachProducts(data) {
    return SuperFetch(`hats/billproducts`, {
      method: 'POST',
      data,
    });
  }

  getMetaKeys(query = { limit: 10, page: 1 }) {
    return SuperFetch(`${API_ENDPOINTS.META_KEYS}?${querparser.stringify(query)}`);
  }

  getMetaValues(query = { limit: 10, page: 1 }) {
    return SuperFetch(`${API_ENDPOINTS.META_VALUES}?${querparser.stringify(query)}`);
  }

  respond({ id, data }) {
    return SuperFetch(`hats/bills/${id}/status`, {
      method: 'POST',
      data: data,
    });
  }
}

export default new Cashbacks();
