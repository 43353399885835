import React from 'react';
import { Route } from 'react-router-dom';
import hasPermission from 'utils/adminPermission/hasPermission';
import NoPermission from './../components/NoPermission';

export default function PermittedRoute({ component: Component, ...rest }) {
  const isPermitted = hasPermission(rest.permissions);

  return (
    <Route
      {...rest}
      render={(props) => (isPermitted ? <Component {...props} /> : <NoPermission />)}
    />
  );
}
