import SuperFetch from '../api/SuperFetch';
import querparser from 'query-string';
class PSReview {
  getAll(query = { limit: 10, page: 1 }) {
    return SuperFetch(`hats/playstore-review?${querparser.stringify(query)}`);
  }

  psAcceptToggle(id, query) {
    return SuperFetch(`hats/playstore-review/${id}/status?status=${query}`, {
      method: 'POST',
    });
  }
}

export default new PSReview();
