import { createSlice, createSelector } from '@reduxjs/toolkit';
import Tags from '../repositories/Tags';
import { enqueueSnackbar } from './notifications';

const tagsSlice = createSlice({
  name: 'tags',
  initialState: {
    loading: false,
    error: null,
    data: [],
  },
  reducers: {
    getAll: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    getAllSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.data;
    },
    getAllFailed: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    create: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    createSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data.push(action.payload.data);
    },
    createFailed: (state, action) => {
      state.loading = false;
      state.error = null;
    },
    edit: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    editSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = state.data.map(item => {
        if (item.id === action.payload.id) {
          return action.payload;
        }
        return item;
      });
    },
    editFailed: (state, action) => {
      state.loading = false;
      state.error = null;
    },
  },
});

const { actions, reducer } = tagsSlice;

const getTags = state => state.tags.data;
const getIsLoading = state => state.tags.loading;

const selectors = {
  data: createSelector(
    getTags,
    data => data,
  ),
  isLoading: createSelector(
    getIsLoading,
    data => data,
  ),
  selectOptions: createSelector(
    getTags,
    data => data.map(({ name }) => ({ value: name, label: name })),
  ),
};

const getAll = query => async dispatch => {
  dispatch(actions.getAll());
  try {
    const response = await Tags.getAll(query);
    if (response.success || response.status === 200) {
      dispatch(actions.getAllSuccess(response));
      return {
        data: response.data,
        page: response.current_page - 1,
        totalCount: response.total,
      };
    } else {
      throw Error(response.data);
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.getAllFailed(error.message));
    return error;
  }
};

const create = values => async (dispatch, getState) => {
  dispatch(actions.create());
  try {
    const response = await Tags.create(values);
    if (response.success || response.status === 201) {
      dispatch(actions.createSuccess(response));
      dispatch(
        enqueueSnackbar({
          message: 'Tag Created Succesfully.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );
    } else {
      const errMsg =
        response?.data?.errors &&
        Object.keys(response?.data?.errors || {})
          ?.map(er => response?.data?.errors[er])
          ?.join(', ');
      dispatch(
        enqueueSnackbar({
          message: `${response?.data?.message} - ${errMsg}`,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.createFailed(error.message));
    dispatch(
      enqueueSnackbar({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
  }
};

const edit = (id, values) => async (dispatch, getState) => {
  dispatch(actions.edit());
  try {
    const body = {};
    if (values.name) {
      body.name = values.name;
    }
    if (values.description) {
      body.description = values.description;
    }
    if (Object.keys(body).length) {
      const response = await Tags.edit(id, body);
      if (response.success || response.status === 200) {
        dispatch(actions.editSuccess(response.data));
        dispatch(
          enqueueSnackbar({
            message: 'Tag Edited Succesfully.',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          }),
        );
      } else {
        const errMsg =
          response?.data?.errors &&
          Object.keys(response?.data?.errors || {})
            ?.map(er => response?.data?.errors[er])
            ?.join(', ');
        console.log(errMsg);
        dispatch(
          enqueueSnackbar({
            message: `${response?.data?.message} - ${errMsg}`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error',
            },
          }),
        );
      }
    }
  } catch (error) {
    console.log(error);
    dispatch(actions.editFailed(error.message));
    dispatch(
      enqueueSnackbar({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
    return error;
  }
};

export default {
  actions,
  reducer,
  selectors,
  getAll,
  create,
  edit,
};
