import SuperFetch, { SuperUploader } from '../api/SuperFetch';
import querparser from 'query-string';

class Banners {
  getAll(query = { limit: 10, page: 1 }) {
    return SuperFetch(`hats/banners?${querparser.stringify(query)}`);
  }

  createBanner(query, formData) {
    return SuperUploader(`hats/banners?${querparser.stringify(query)}`, {
      body: formData,
    });
  }

  updateBanner(id, query, formData) {
    return SuperUploader(`hats/banners/${id}?${querparser.stringify(query)}`, {
      body: formData,
    });
  }

  deleteBanner(id) {
    return SuperFetch(`hats/banners/${id}/delete`, {
      method: 'POST',
    });
  }

  bannerStatusToggle(id, status) {
    return SuperFetch(`hats/banners/${id}/status?status=${status}`, {
      method: 'POST',
    });
  }
}

export default new Banners();
