import BaseApi from 'api/BaseApi';
import querparser from 'query-string';

export default class TopupApi extends BaseApi {
  static getAll = (key, query = { limit: 10, page: 1, sort: 'id', order: 'desc' }) => {
    return this.Axios.get(
      `hats/topups?${querparser.stringify(query, {
        skipEmptyString: true,
        skipNull: true,
      })}`,
    );
  };

  static toggleActive = (id, status) => {
    const data = {
      ids: id,
      is_enable: Boolean(status),
    };
    return this.Axios.post(`hats/topups/topup-config`, data);
  };

  static getAllOperatorList = () => {
    return this.Axios.get('hats/topups/topup-config');
  };
}
